<template>
  <div class="space-y-4 lg:space-y-8">
    <h2 class="text-xl font-light uppercase text-center space-y-2">
      <SvgIcon icon="library" />
      <div>Tell us about your church</div>
    </h2>

    <div>
      <label>{{ organizationLabel }}</label>
      <TextInput v-model="state.formData.organization.name" />
      <FieldError :error="errors.name" />
    </div>

    <div class="grid grid-cols-2 gap-4">
      <div>
        <label>Denomination</label>
        <SelectInput
          v-model="state.formData.organization.denomination"
          :options="denominations"
          includeBlank
        />
        <FieldError :error="errors.denomination" />
      </div>

      <div>
        <label>Church Size</label>
        <SelectInput
          v-model="state.formData.organization.size"
          :options="sizeOptions"
          includeBlank
        />
        <FieldError :error="errors.size" />
      </div>
    </div>

    <div>
      <label>Phone Number</label>
      <PhoneNumberInput v-model="state.formData.organization.phone_number" />
      <FieldError :error="errors.phone_number" />
    </div>

    <div class="grid grid-cols-1 md:grid-cols-2 gap-4 lg:gap-8">
      <div class="md:col-span-2">
        <label>Street Address</label>
        <TextInput v-model="state.formData.organization.address_street" />
        <FieldError :error="errors.address_street" />
      </div>

      <div>
        <label>City</label>
        <TextInput v-model="state.formData.organization.address_city" />
        <FieldError :error="errors.address_city" />
      </div>

      <div>
        <label>State</label>
        <SelectInput
          v-model="state.formData.organization.address_state"
          :options="stateCodeOptions"
          includeBlank
        />
        <FieldError :error="errors.address_state" />
      </div>

      <div>
        <label>Postal Code</label>
        <TextInput v-model="state.formData.organization.address_postal_code" />
        <FieldError :error="errors.address_postal_code" />
      </div>
    </div>

    <div class="grid grid-cols-2 gap-4 pt-4">
      <Button variant="secondary" @click="back">
        <SvgIcon icon="cheveron-left" />
        <span class="inline-block ml-1">Back</span>
      </Button>

      <Button variant="primary" @click="next">
        <span class="inline-block mr-1">Next</span>
        <SvgIcon icon="cheveron-right" />
      </Button>
    </div>
  </div>
</template>

<script setup>
import { ref, computed } from 'vue'
import TextInput from '@/vue_components/TextInput.vue'
import FieldError from '@/vue_components/FieldError.vue'
import PhoneNumberInput from '@/vue_components/PhoneNumberInput.vue'
import SelectInput from '@/vue_components/SelectInput.vue'
import SvgIcon from '@/vue_components/SvgIcon.vue'
import Button from '@/vue_components/Button.vue'
import { stateCodes } from '@/country_states.js'
import { denominations, sizeOptions } from '@/church_information.js'
import { requestJSON } from '@/requests.js'
import useStore from '../store.js'

const emit = defineEmits(['back', 'next'])

const { state, selectedSubscriptionType } = useStore()
const errors = ref({})

const organizationLabel = computed(() =>
  selectedSubscriptionType.value.category === 'family' ? 'Church You Attend' : 'Organization Name',
)
const stateCodeOptions = computed(() => stateCodes)

const back = () => {
  emit('back')
}

const next = async () => {
  errors.value = {}

  const { body } = await requestJSON('/join', { method: 'post', body: state.formData })

  if (body.errors && body.errors.organization && Object.keys(body.errors.organization).length > 0) {
    errors.value = body.errors.organization
  } else {
    emit('next')
  }
}
</script>
