<template>
  <InlineSvg :src="src" class="inline-block align-middle fill-current" :class="dynamicClasses" />
</template>

<script>
import { computed } from 'vue'
import InlineSvg from '@/vue_components/InlineSvg.vue'

export default {
  components: {
    InlineSvg,
  },
  props: {
    icon: {
      type: String,
    },
    size: {
      type: String,
      default: 'h-6',
    },
  },
  setup(props) {
    const src = new URL(`/images/icons/${props.icon}.svg`, import.meta.url)

    const dynamicClasses = computed(() => [props.size])

    return {
      src: src.href,
      dynamicClasses,
    }
  },
}
</script>
