import { reactive, readonly } from 'vue'

export const useState = (initialState) => {
  const state = reactive(initialState)

  const setState = (updatedState = {}) => {
    Object.keys(updatedState).forEach((key) => {
      state[key] = updatedState[key]
    })
  }

  const asComputeds = () =>
    Object.keys(state).reduce((computeds, key) => {
      computeds[key] = computed(() => state[key])
    }, {})

  return {
    state,
    setState,
    asComputeds,
  }
}
