<template>
  <WidthWrapper class="mx-auto mt-8">
    <div class="p-4 space-y-4">
      <h1 class="block text-xl font-extrabold">Subscriptions</h1>

      <div class="relative">
        <TextInput
          v-model="queryInput"
          @keydown.enter="applySearchQuery"
          placeholder="Search by organization or person"
        />
        <SvgIcon
          icon="search"
          size="h-4"
          class="absolute right-4 top-1/2 -translate-y-1/2 text-gray-500"
        />
      </div>

      <div v-if="data.rows.length > 0" class="space-y-4">
        <Table :headers="data.headers" :rows="data.rows">
          <template #subscription_type-cell="{ row }">
            <div
              class="inline-flex items-center text-sm font-bold uppercase"
              :class="row.category == 'organization' ? 'text-purple-500' : 'text-green-500'"
            >
              <SvgIcon
                :icon="row.category == 'organization' ? 'library' : 'home'"
                size="h-4 mr-2"
              />
              <span>{{ row.category }}</span>
            </div>
          </template>

          <template #name-cell="{ row }">
            <Link :href="subscription_subscription_path(row.id)">
              <div class="font-medium capitalize">
                {{ row.name }}
              </div>
            </Link>
          </template>

          <template #owners-cell="{ row }">
            <div class="text-sm text-gray-900">
              {{ row.owners.map((owner) => owner.user.full_name).join(', ') }}
            </div>
          </template>

          <template #created_at-cell="{ row }">
            <div class="text-sm text-gray-900">
              {{ fullDate(row.created_at) }}
            </div>
          </template>

          <template #next_renewal-cell="{ row }">
            <div class="text-sm text-gray-900">
              <span v-if="row.needs_setup" class="text-gray-300">&ndash;</span>
              <span v-else>{{ fullDate(row.next_renewal_at) }}</span>
            </div>
          </template>

          <template #students-cell="{ row }">
            <span class="inline-flex items-center space-x-2 text-gray-500">
              <SvgIcon icon="user" size="h-4" />
              <span>
                {{ row.passes_used }} /
                {{ row.total_passes }}
              </span>
            </span>
          </template>

          <template #status-cell="{ row }">
            <span :class="row.status.class">
              {{ row.status.display }}
            </span>
          </template>
        </Table>

        <PaginationControls :pagination="pagination" :load-page="loadPage" />
      </div>

      <div v-else-if="query">
        <Box>
          <span class="text-gray-500">No subscriptions found matching "{{ query }}"</span>
        </Box>
      </div>

      <div v-else>
        <Box>
          <span class="text-gray-500">There are no subscriptions yet</span>
        </Box>
      </div>
    </div>

    <Modal v-if="loading" content-size="content">
      <div class="flex justify-center p-8">
        <LoadingSpinner class="text-blue-500" />
      </div>
    </Modal>
  </WidthWrapper>
</template>

<script setup>
import { computed, ref } from 'vue'
import WidthWrapper from '@/vue_components/WidthWrapper.vue'
import Modal from '@/vue_components/Modal.vue'
import Box from '@/vue_components/Box.vue'
import SvgIcon from '@/vue_components/SvgIcon.vue'
import Link from '@/vue_components/Link.vue'
import LoadingSpinner from '@/vue_components/LoadingSpinner.vue'
import Button from '@/vue_components/Button.vue'
import Table from '@/vue_components/Table.vue'
import TextInput from '@/vue_components/TextInput.vue'
import PaginationControls from '@/vue_components/PaginationControls.vue'
import { fullDate } from '@/formatting.js'
import useStore from '../store.js'
import { subscriptions_path, subscription_subscription_path } from '@/routes.js'

const { state, loadSubscriptions, searchSubscriptions } = useStore()

const queryInput = ref(null)
const loading = computed(() => state.loading)
const subscriptions = computed(() => state.subscriptions)
const pagination = computed(() => state.pagination)
const query = computed(() => state.query)

const subscriptionStatus = (subscription) => {
  if (subscription.needs_setup) {
    return { display: 'Needs Setup', class: 'text-blue-500' }
  } else if (subscription.free_trial_active) {
    return { display: 'Free Trial', class: 'text-pink-500' }
  } else if (subscription.marked_for_cancellation) {
    return { display: 'Cancelled', class: 'font-bold text-red-500' }
  } else if (subscription.locked) {
    return { display: 'Locked', class: 'font-bold text-black' }
  } else if (subscription.failed_renewal) {
    return { display: 'Renewal Failed', class: 'text-yellow-500' }
  } else {
    return { display: 'Active', class: 'text-green-500' }
  }
}

const data = computed(() => {
  return {
    headers: [
      { display: 'Type', field: 'subscription_type' },
      { display: 'Name', field: 'name' },
      { display: 'Owner(s)', field: 'owners' },
      { display: 'Joined', field: 'created_at' },
      { display: 'Next Renewal', field: 'next_renewal' },
      { display: 'Students', field: 'students' },
      { display: 'Status', field: 'status' },
    ],
    rows: subscriptions.value.map((subscription) => {
      return {
        ...subscription,
        status: subscriptionStatus(subscription),
      }
    }),
  }
})

const loadPage = (page) => {
  loadSubscriptions({ page })
}

const applySearchQuery = () => {
  searchSubscriptions(queryInput.value)
}
</script>
