<template>
  <div>
    <div class="space-y-4">
      <Box v-for="(parent, index) of formData.parents" :key="index" class="relative space-y-2">
        <p class="font-light text-gray-500">Parent {{ index + 1 }}</p>

        <button
          v-if="canRemoveParents"
          type="button"
          class="absolute top-2 right-4 inline-flex items-center text-red-500 space-x-1"
          @click="removeParentAtIndex(index)"
        >
          <SvgIcon icon="trash" size="h-5" />
          <span>Remove</span>
        </button>

        <div class="grid grid-cols-2 gap-4">
          <div>
            <label>First Name</label>
            <TextInput v-model="formData.parents[index].first_name" />
            <FieldError :error="parentErrors(index)?.first_name" />
          </div>

          <div>
            <label>Last Name</label>
            <TextInput v-model="formData.parents[index].last_name" />
            <FieldError :error="parentErrors(index)?.last_name" />
          </div>

          <div>
            <label>Relationship</label>
            <TextInput v-model="formData.parents[index].relationship" />
            <FieldError :error="parentErrors(index)?.relationship" />
          </div>

          <div>
            <label>Email</label>
            <TextInput v-model="formData.parents[index].email" />
            <FieldError :error="parentErrors(index)?.email" />
          </div>

          <div>
            <label>Cell Phone</label>
            <PhoneNumberInput v-model="formData.parents[index].cell_phone" />
            <FieldError :error="parentErrors(index)?.cell_phone" />
          </div>
        </div>
      </Box>
    </div>

    <div class="p-4 text-center">
      <Button variant="bordered" class="space-x-2" @click="addParent">
        <SvgIcon icon="add-outline" />
        <span>Add Parent</span>
      </Button>
    </div>
  </div>
</template>

<script setup>
import { computed, ref, inject } from 'vue'
import Box from '@/vue_components/Box.vue'
import Button from '@/vue_components/Button.vue'
import TextInput from '@/vue_components/TextInput.vue'
import PhoneNumberInput from '@/vue_components/PhoneNumberInput.vue'
import SelectInput from '@/vue_components/SelectInput.vue'
import FieldError from '@/vue_components/FieldError.vue'
import SvgIcon from '@/vue_components/SvgIcon.vue'
import { requestJSON } from '@/requests.js'

const membership = inject('membership')
const formData = inject('formData')
const errors = inject('errors')

const addParent = () => {
  formData.value.parents.push({})
}

const removeParentAtIndex = (index) => {
  const parents = [...formData.value.parents]
  parents.splice(index, 1)
  formData.value.parents = parents
}

const canRemoveParents = computed(() => formData.value.parents && formData.value.parents.length > 1)

const parentErrors = (index) => {
  const parents = errors.value?.parents || []
  return parents ? parents[index]?.membership : {}
}
</script>
