<template>
  <Box class="space-y-1 text-left">
    <div class="flex justify-between w-full">
      <div class="font-bold">{{ paymentMethod.name }}</div>
      <div class="space-x-4">
        <slot name="actions" />
      </div>
    </div>

    <div class="space-x-2">
      <span class="inline-flex uppercase text-sm bold bg-gray-200 text-blue-500 px-2 py-1">
        {{ paymentMethod.brand }}
      </span>

      <span class="inline-flex space-x-2">
        <span v-for="i in 3">&middot;&middot;&middot;&middot;</span>
        <span>{{ paymentMethod.last_4 }}</span>
      </span>

      <span class="inline-block text-gray-400 ml-4">{{ paymentMethod.exp_month }} / {{ paymentMethod.exp_year }}</span>

      <span v-if="paymentMethod.is_default" class="inline-block text-sm rounded-sm px-2 py-1 bg-green-500 text-white">
        Default
      </span>
    </div>
  </Box>
</template>

<script>
import Box from '@/vue_components/Box.vue'

export default {
  components: {
    Box,
  },
  props: {
    paymentMethod: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    return {}
  },
}
</script>
