import { createApp, h } from 'vue'
import PasswordUpdate from './components/PasswordUpdate.vue'

export default ({ el, props }) => {
  const app = createApp({
    render() {
      return h(PasswordUpdate, props)
    },
  })

  return app.mount(el)
}
