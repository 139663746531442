<template>
  <div :class="shakeClasses" :style="shakeStyles" @animationend="reset">
    <slot :shake="shake"></slot>
  </div>
</template>

<script>
import { ref } from 'vue'

export default {
  props: {
    iterations: {
      type: Number,
      default: 2,
    },
  },
  setup(props) {
    const shakeClasses = ref({})
    const shakeStyles = ref({})
    const shake = () => {
      shakeClasses.value = { 'animate-shake': true }
      shakeStyles.value = { 'animation-iteration-count': props.iterations }
    }
    const reset = () => {
      shakeClasses.value = {}
      shakeStyles.value = {}
    }

    return {
      shakeClasses,
      shakeStyles,
      shake,
      reset,
    }
  },
}
</script>
