import { useState } from '@/states.js'
import { requestJSON } from '@/requests.js'
import { subscriptions_path } from '@/routes.js'

const { state, setState } = useState({
  loading: false,
  subscriptions: [],
  pagination: {},
  query: null,
})

const loadSubscriptions = async ({ page = undefined } = {}) => {
  setState({ loading: true })

  const { body } = await requestJSON(subscriptions_path({ page: page || 1, query: state.query }))

  if (body) {
    setState({ loading: false, ...body })
  } else {
    setState({ loading: false })
  }
}

const searchSubscriptions = async (query) => {
  setState({ query })
  loadSubscriptions()
}

const useStore = () => {
  return {
    state,
    setState,
    loadSubscriptions,
    searchSubscriptions,
  }
}

export default useStore
