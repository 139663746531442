import { requestJSON } from '@/requests.js'

export const getCSRFToken = () => {
  const param = document.querySelector('meta[name=csrf-param]').content
  const token = document.querySelector('meta[name=csrf-token]').content

  return { param, token }
}

export const submit = async ({ form, formData }) => {
  const { response, body } = await requestJSON(form.action, {
    method: form.method,
    body: formData || new FormData(form),
  })

  if (!response.ok) {
    return {
      errors: {
        base: 'Something went wrong. Try again!',
      },
    }
  }

  if (body.errors) {
    return { errors: body.errors }
  } else {
    return body
  }
}
