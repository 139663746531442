<template>
  <div class="space-y-4">
    <div>
      <p class="text-xl font-bold">{{ formData.first_name }} {{ formData.last_name }}</p>
      <p>{{ formData.email }}</p>
      <p>{{ phoneNumber(formData.cell_phone) }}</p>
      <p>{{ formData.grade_level }} @ {{ formData.school }}</p>
    </div>

    <div>
      <p class="uppercase text-gray-500 text-sm">Parent(s)</p>
      <div class="space-y-2">
        <Box v-for="parent of formData.parents" :key="parent.key">
          <p>
            {{ parent.first_name }} {{ parent.last_name }}
            <span class="text-gray-500">({{ parent.relationship }})</span>
          </p>
          <p class="text-sm">
            {{ parent.email }} &middot; {{ phoneNumber(parent.cell_phone || '') }}
          </p>
        </Box>
      </div>
    </div>

    <div v-if="mentor">
      <p class="uppercase text-gray-500 text-sm">Mentor</p>
      <Box>
        <p>{{ mentor.full_name }}</p>
        <p class="text-sm">
          {{ mentor.email }} &middot;
          {{ phoneNumber(mentor.cell_phone || '') }}
        </p>
      </Box>
    </div>

    <div class="grid grid-cols-2 gap-4 pt-4">
      <Button variant="secondary" @click="action('back')">Back</Button>
      <Button variant="primary" :disabled="currentState != 'review' || submitting" type="submit">
        <SvgIcon icon="envelope" />
        <span class="inline-block ml-2">Send Invitation</span>
      </Button>
    </div>
  </div>
</template>

<script setup>
import { computed, inject } from 'vue'
import Button from '@/vue_components/Button.vue'
import SvgIcon from '@/vue_components/SvgIcon.vue'
import Box from '@/vue_components/Box.vue'
import { phoneNumber } from '@/formatting.js'
import useStore from '../../store.js'

const formData = inject('formData')
const errors = inject('errors')
const action = inject('action')
const submitting = inject('submitting')
const currentState = inject('currentState')

const { state } = useStore()
const mentors = computed(() => state.subscription.mentors || [])
const mentorId = computed(() => formData.value.mentor_relationship_attributes?.mentor_id)
const mentor = computed(() =>
  mentorId.value ? mentors.value.find((mentor) => mentor.id == mentorId.value) : null,
)
</script>
