import { createApp, h } from 'vue'
import SiteHeader from './components/SiteHeader.vue'

export default ({ el, props = {}, state = {} } = {}) => {
  const app = createApp({
    render() {
      return h(SiteHeader, props)
    },
  })

  return app.mount(el)
}
