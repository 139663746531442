<template>
  <div class="relative">
    <TextInput type="password" v-model="value" @focus="onFocus" @blur="onBlur" :name="name" />

    <div
      v-show="focussed"
      class="
        absolute
        w-96
        left-1/2
        top-full
        -translate-x-1/2
        bg-white
        text-black
        shadow-md
        rounded-lg
        mt-1
        p-4
        border border-solid border-gray-200
      "
    >
      <div v-for="rule of rules">{{ ruleIcon(rule) }} {{ rule.description }}</div>
    </div>
  </div>
</template>

<script setup>
import { ref, computed } from 'vue'
import TextInput from './TextInput.vue'

const props = defineProps({
  modelValue: {
    type: String,
    default: '',
  },
  name: {
    type: String,
    default: null,
  },
})

const emit = defineEmits(['update:modelValue'])

const focussed = ref(false)

const value = computed({
  get: () => props.modelValue,
  set: (value) => emit('update:modelValue', value),
})

const rules = [
  {
    description: 'At least 8 characters',
    validation: (password) => password.length >= 8,
  },
  {
    description: 'At least 1 number',
    validation: (password) => password.match(/[\d]/),
  },
  {
    description: 'At least 1 special character',
    validation: (password) => password.match(/[^\d\w]/),
  },
]

const ruleIcon = (rule) => {
  return rule.validation(value.value) ? '✅' : '🚫'
}

const passwordIsValid = (password) => {
  return rules.every((rule) => rule.validation(password))
}

const onFocus = () => {
  focussed.value = true
}

const onBlur = () => {
  focussed.value = false
  if (!passwordIsValid(value.value)) {
    emit('update:modelValue', '')
  }
}
</script>
