<template>
  <div class="space-y-2 w-full sm:w-1/2 sm:mx-auto">
    <p class="text-center text-gray-600">{{ paginationDescription }}</p>

    <div class="grid grid-cols-2 gap-4">
      <Button
        variant="primary"
        @click="loadPage(pagination.prev_page)"
        :disabled="!pagination.prev_page"
      >
        Previous
      </Button>

      <Button
        variant="primary"
        @click="loadPage(pagination.next_page)"
        :disabled="!pagination.next_page"
      >
        Next
      </Button>
    </div>
  </div>
</template>

<script setup>
import { computed } from 'vue'
import Button from '@/vue_components/Button.vue'

const props = defineProps({
  pagination: Object,
  loadPage: Function,
})

const paginationDescription = computed(() => {
  if (!props.pagination) return ''
  if (!props.pagination.total_count) return 'Showing all'

  const total = props.pagination.total_count
  const pageStart = (props.pagination.current_page - 1) * props.pagination.page_size + 1
  const pageEnd = Math.min(pageStart + props.pagination.page_size - 1, total)

  return `Showing ${pageStart} - ${pageEnd} of ${total}`
})
</script>
