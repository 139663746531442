<template>
  <div class="space-y-4">
    <div class="grid grid-cols-2 gap-4">
      <div>
        <label>First Name</label>
        <TextInput type="text" v-model="formData.first_name" />
        <FieldError :error="errors.membership?.first_name" />
      </div>

      <div>
        <label>Last Name</label>
        <TextInput type="text" v-model="formData.last_name" />
        <FieldError :error="errors.membership?.last_name" />
      </div>
    </div>

    <div>
      <label>Email</label>
      <TextInput type="text" v-model="formData.email" />
      <FieldError :error="errors.membership?.email" />
    </div>

    <div>
      <label>Cell Phone</label>
      <PhoneNumberInput v-model="formData.cell_phone" />
      <FieldError :error="errors.membership?.cell_phone" />
    </div>

    <div class="grid grid-cols-2 gap-4">
      <div>
        <label>School</label>
        <TextInput type="text" v-model="formData.school" />
        <FieldError :error="errors.membership?.school" />
      </div>

      <div>
        <label>Grade Level</label>
        <SelectInput :options="GRADE_LEVELS" v-model="formData.grade_level" />
        <FieldError :error="errors.membership?.grade_level" />
      </div>
    </div>

    <div class="grid grid-cols-2 gap-4 pt-4">
      <div></div>
      <Button variant="primary" @click="next">
        <span class="inline-block mr-1">Parents</span>
        <SvgIcon icon="cheveron-right" />
      </Button>
    </div>
  </div>
</template>

<script setup>
import { inject, ref, computed } from 'vue'
import Button from '@/vue_components/Button.vue'
import TextInput from '@/vue_components/TextInput.vue'
import PhoneNumberInput from '@/vue_components/PhoneNumberInput.vue'
import SelectInput from '@/vue_components/SelectInput.vue'
import FieldError from '@/vue_components/FieldError.vue'
import SvgIcon from '@/vue_components/SvgIcon.vue'
import { validate_membership_invitations_path } from '@/routes.js'
import { requestJSON } from '@/requests.js'

const GRADE_LEVELS = ['', 'Middle School', '9th', '10th', '11th', '12th', 'College']
const FIELDS = ['first_name', 'last_name', 'email', 'cell_phone', 'school', 'grade_level']

const formData = inject('formData')
const action = inject('action')
const submissionErrors = inject('errors')
const validationErrors = ref({})

const errors = computed(() => ({
  membership: {
    ...(submissionErrors.value.membership || {}),
    ...(validationErrors.value.membership || {}),
  },
}))

const slice = (object, ...keys) => {
  return keys.reduce((all, key) => {
    if (object.hasOwnProperty(key)) {
      all[key] = object[key]
    }
    return all
  }, {})
}

const validate = async () => {
  validationErrors.value = {}

  const { body } = await requestJSON(validate_membership_invitations_path(), {
    method: 'post',
    body: { membership: formData.value },
  })

  if (body.errors) {
    const studentErrors = slice(body.errors.membership, ...FIELDS)

    if (Object.keys(studentErrors).length) {
      validationErrors.value = { membership: studentErrors }
      return false
    } else {
      return true
    }
  } else {
    return true
  }
}

const next = async () => {
  const valid = await validate()

  if (valid) {
    action('next')
  }
}
</script>
