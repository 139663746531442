import { DateTime } from 'luxon'

export const phoneNumber = (phoneNumberString, options = {}) => {
  const { allowExtension = false } = options
  const digits = phoneNumberString.replace(/\D/g, '')
  const first3 = digits.slice(0, 3)
  const second3 = digits.slice(3, 6)
  const last4 = digits.slice(6, 10)
  const extension = digits.slice(10)

  let formatted = ''

  if (first3) {
    formatted += `(${first3}`

    if (second3) {
      formatted += `) ${second3}`

      if (last4) {
        formatted += `-${last4}`

        if (extension && allowExtension) {
          formatted += ` ext. ${extension}`
        }
      }
    }
  }

  return formatted
}

export const fullDate = (dateString) => {
  return DateTime.fromISO(dateString).toLocaleString(DateTime.DATE_FULL)
}
