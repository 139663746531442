<template>
  <BrandedLayout title="Join" :background="joinBackground" theme="light">
    <SubscriptionType v-if="currentStep == 'subscriptionType'" @next="currentStep = 'owner'" />
    <Owner
      v-if="currentStep == 'owner'"
      @back="currentStep = 'subscriptionType'"
      @next="currentStep = 'organization'"
    />
    <Organization
      v-if="currentStep == 'organization'"
      @back="currentStep = 'owner'"
      @next="currentStep = 'subscription'"
    />
    <Subscription
      v-if="currentStep == 'subscription'"
      @back="currentStep = 'organization'"
      @next="currentStep = 'account'"
    />
    <Account
      v-if="currentStep == 'account'"
      @back="currentStep = 'subscription'"
      @next="currentStep = 'review'"
    />
    <Review v-if="currentStep == 'review'" @back="currentStep = 'account'" />
  </BrandedLayout>
</template>

<script setup>
import { ref, computed } from 'vue'
import BrandedLayout from '@/vue_components/BrandedLayout.vue'
import SubscriptionType from './SubscriptionType.vue'
import Organization from './Organization.vue'
import Owner from './Owner.vue'
import Subscription from './Subscription.vue'
import Account from './Account.vue'
import Review from './Review.vue'
import Button from '@/vue_components/Button.vue'
import joinBackground from '@/images/login-background.jpg'

const currentStep = ref('subscriptionType')
</script>
