<template>
  <div>
    <div v-if="subscription.free_trial_active">
      <Box>Student passes are not available during the free trial period.</Box>
    </div>
    <div v-else class="space-y-4">
      <div class="grid xl:grid-cols-3 lg:grid-cols-2 gap-4">
        <Box class="relative overflow-hidden">
          <div class="space-y-2">
            <p>
              <span
                class="text-6xl align-middle pr-2"
                :class="{ 'text-red-500': subscription.passes_remaining == 0 }"
              >
                {{ subscription.passes_remaining }}
              </span>
              <span class="text-2xl align-middle">/ {{ subscription.total_passes }}</span>
            </p>
            <p class="font-light">
              {{ pluralize('pass', subscription.passes_remaining, { suffix: 'es' }) }} remaining
            </p>
          </div>

          <div v-if="can.addPasses" class="absolute top-0 right-0">
            <router-link to="subscription" class="inline-block">
              <span
                class="
                  flex
                  items-center
                  space-x-2
                  font-bold
                  bg-green-500
                  text-white
                  px-4
                  py-2
                  text-right
                "
              >
                <SvgIcon icon="add-outline" />
                <span>Get more passes</span>
              </span>
            </router-link>
          </div>
        </Box>

        <Box class="relative">
          <div class="space-y-2">
            <p class="text-6xl">{{ invites.length }}</p>
            <p class="font-light">pending {{ pluralize('invitation', invites.length) }}</p>
          </div>

          <div class="absolute top-0 right-0 mr-4 mt-4">
            <span title="Pending invitations count against your available passes.">
              <SvgIcon icon="question" class="text-gray-500 cursor-help" />
            </span>
          </div>
        </Box>
      </div>

      <div class="pt-6 space-y-4">
        <Table
          v-if="memberData.rows.length > 0"
          :headers="memberData.headers"
          :rows="memberData.rows"
        >
          <template #name-cell="{ row }">
            <Button v-if="can.editMembers" variant="link" @click="editingMembership = row.member">
              {{ row.name }}
            </Button>
            <span v-else>{{ row.name }}</span>
          </template>

          <template #mentor-cell="{ row }">
            <Button
              v-if="can.editMembers"
              variant="secondary"
              class="w-full"
              @click="choosingMentor = row.member"
            >
              <span v-if="row.mentor">
                {{ row.mentor?.full_name }}
              </span>
              <span v-else class="text-gray-500">Not assigned</span>
            </Button>
            <span v-else>{{ row.mentor?.full_name || 'Not assigned' }}</span>
          </template>

          <template #status-cell="{ row }">
            <div v-if="can.editMembers">
              <ActionButton v-if="row.active">
                <span class="text-green-500 font-bold">Active</span>

                <template v-slot:actions="{ perform, buttonClasses }">
                  <div class="w-max">
                    <button
                      :class="buttonClasses.destructive"
                      @click="perform(() => (deletingMember = row))"
                    >
                      Remove Member
                    </button>
                  </div>
                </template>
              </ActionButton>

              <ActionButton v-else>
                <span class="text-gray-400 italic">Invitation Pending</span>

                <template v-slot:actions="{ perform, buttonClasses }">
                  <div class="w-max">
                    <button
                      :class="buttonClasses.default"
                      @click="perform(() => resendInvitation(row))"
                    >
                      Resend Invitation
                    </button>
                    <button
                      :class="buttonClasses.destructive"
                      @click="perform(() => (deletingInvitation = row))"
                    >
                      Cancel Invitation
                    </button>
                  </div>
                </template>
              </ActionButton>
            </div>
            <div v-else>
              {{ row.active ? 'Active' : 'Invitation Pending' }}
            </div>
          </template>
        </Table>

        <div v-else-if="showFirstStudentAlert" class="animate-move-up-and-down">
          <p class="text-center w-[600px] mx-auto text-gray-500">
            Now is a great time to invite your first student!
            <br />
            <span>⬇️</span>
          </p>
        </div>

        <Box v-if="subscription.passes_remaining > 0" class="space-y-2 text-center">
          <p>
            You have
            {{
              pluralize('pass', subscription.passes_remaining, { suffix: 'es', inclusive: true })
            }}
            remaining.
          </p>

          <InflunsrButton
            v-if="can.inviteMembers"
            variant="custom"
            class="bg-green-500 text-white font-bold space-x-2"
            @click="showInviteMemberModal = true"
          >
            <SvgIcon icon="user-add" />
            <span>Invite new student</span>
          </InflunsrButton>

          <p v-if="subscription.free_trial_active" class="text-gray-400">
            Your subscription is still in the free trial period. You will be able to invite students
            once your subscription is fully activated.
          </p>
        </Box>
      </div>
    </div>

    <InviteMemberModal
      v-if="showInviteMemberModal"
      @close="showInviteMemberModal = false"
      @cancel="showInviteMemberModal = false"
    />

    <ConfirmationModal
      v-if="deletingMember"
      @confirm="handleDeleteMember(deletingMember)"
      @deny="deletingMember = null"
    >
      <div class="text-center p-4 space-y-4">
        <SvgIcon icon="exclamation-outline" class="text-yellow-400 h-20" />
        <p>
          Are you sure you want to remove {{ deletingMember.first_name }}
          {{ deletingMember.last_name }}'s membership?
        </p>
      </div>
    </ConfirmationModal>

    <ConfirmationModal
      v-if="deletingInvitation"
      @confirm="handleDeleteInvitation(deletingInvitation)"
      @deny="deletingInvitation = null"
    >
      <div class="text-center p-4 space-y-4">
        <SvgIcon icon="exclamation-outline" class="text-yellow-400 h-20" />
        <p>
          Are you sure you want to cancel {{ deletingInvitation.first_name }}
          {{ deletingInvitation.last_name }}'s invitation?
        </p>
      </div>
    </ConfirmationModal>

    <ChooseMentorModal
      v-if="choosingMentor"
      :for-student="choosingMentor"
      @close="choosingMentor = null"
      @backdrop-click="choosingMentor = null"
    />

    <MembershipDetailsModal
      v-if="viewingMembership"
      :membership="viewingMembership"
      @close="viewingMembership = null"
      @backdrop-click="viewingMembership = null"
    />

    <EditMembershipModal
      v-if="editingMembership"
      :membership="editingMembership"
      @close="editingMembership = null"
    />
  </div>
</template>

<script setup>
import { ref, computed } from 'vue'
import { pluralize } from '@/inflections.js'
import InflunsrButton from '@/vue_components/Button.vue'
import ActionButton from '@/vue_components/ActionButton.vue'
import Shaker from '@/vue_components/Shaker.vue'
import Box from '@/vue_components/Box.vue'
import Button from '@/vue_components/Button.vue'
import SvgIcon from '@/vue_components/SvgIcon.vue'
import Table from '@/vue_components/Table.vue'
import ConfirmationModal from '@/vue_components/ConfirmationModal.vue'
import InviteMemberModal from './InviteMemberModal/Index.vue'
import ChooseMentorModal from './ChooseMentorModal.vue'
import MembershipDetailsModal from './MembershipDetailsModal.vue'
import EditMembershipModal from './EditMembershipModal/Index.vue'
import useStore from '../store.js'
import { resend_membership_invitation_path } from '@/routes.js'
import { request } from '@/requests.js'
import { alert } from '@/alerts.js'

const { state, deleteMember, deleteInvitation, can, showFirstStudentAlert } = useStore()

const subscription = computed(() => state.subscription)
const members = computed(() => state.subscription.subscribers)
const invites = computed(() => state.subscription.subscribers.filter((member) => !member.active))
const showInviteMemberModal = ref(false)

const deletingMember = ref(null)
const deletingInvitation = ref(null)
const choosingMentor = ref(null)
const viewingMembership = ref(null)
const editingMembership = ref(null)

const handleDeleteMember = (member) => {
  deleteMember(member)
  deletingMember.value = null
}

const handleDeleteInvitation = (invitation) => {
  deleteInvitation(invitation)
  deletingInvitation.value = null
}

const resendInvitation = async (member) => {
  const { response } = await request(resend_membership_invitation_path(member.id), {
    method: 'post',
  })

  if (response.ok) {
    alert('Invitation resent')
  }
}

const memberData = computed(() => {
  return {
    headers: [
      { display: 'Name', field: 'name' },
      { display: 'Email', field: 'email' },
      { display: 'Mentor', field: 'mentor' },
      { display: 'Status', field: 'status' },
    ],
    rows: [
      ...members.value.map((member) => ({
        id: member.id,
        name: `${member.first_name} ${member.last_name}`,
        email: member.email,
        mentor: member.mentor,
        active: member.active,
        member,
      })),
    ],
  }
})
</script>
