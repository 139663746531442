<template>
  <div>
    <Box flush>
      <MentorChooser
        :mentors="mentors"
        :selected-mentor-id="formData.mentor_relationship_attributes?.mentor_id"
        @choose-mentor="chooseMentor"
        highlight-selected
      />
    </Box>
  </div>
</template>

<script setup>
import { computed, inject } from 'vue'
import MentorChooser from '../MentorChooser.vue'
import Box from '@/vue_components/Box.vue'
import Button from '@/vue_components/Button.vue'
import SvgIcon from '@/vue_components/SvgIcon.vue'
import useStore from '../../store.js'

const formData = inject('formData')

const { state } = useStore()
const mentors = computed(() => state.subscription.mentors || [])

const chooseMentor = (mentor) => {
  formData.value.mentor_relationship_attributes = { mentor_id: mentor.id }
}
</script>
