<template>
  <div>
    <div class="space-y-8">
      <div class="space-y-4">
        <h1 class="text-xl font-light">Payment Methods</h1>

        <Box v-if="paymentMethods.length === 0">
          <p class="text-gray-400">Nothing here yet!</p>
        </Box>

        <div v-for="paymentMethod in paymentMethods" :key="paymentMethod.id" class="space-y-4">
          <PaymentMethodListItem :payment-method="paymentMethod">
            <template #actions>
              <a
                href="#"
                v-if="!paymentMethod.is_default && can.editPaymentMethods"
                class="text-blue-500"
                @click.prevent="onMakePaymentMethodDefault(paymentMethod)"
              >
                Make Default
              </a>
              <a
                href="#"
                v-if="!paymentMethod.is_default && can.editPaymentMethods"
                class="text-red-500"
                @click.prevent="deletingPaymentMethod = paymentMethod"
              >
                Remove Card
              </a>
            </template>
          </PaymentMethodListItem>
        </div>

        <Button @click="addPaymentMethod" :disabled="!can.addPaymentMethods">
          Add Payment Method
        </Button>
      </div>

      <div class="space-y-4">
        <h1 class="text-xl font-light">Transaction History</h1>

        <TransactionHistory :subscription="subscription" />
      </div>
    </div>

    <AddPaymentMethodModal
      v-if="showAddPaymentModal"
      @added="onPaymentMethodAdded"
      @cancel="cancelAddPaymentMethod"
    />

    <ConfirmationModal
      v-if="deletingPaymentMethod"
      @confirm="onDeletePaymentMethod(deletingPaymentMethod)"
      @deny="deletingPaymentMethod = null"
    >
      <div class="text-center p-4 space-y-4">
        <SvgIcon icon="exclamation-outline" class="text-yellow-400 h-20" />

        <p>Are you sure you want to remove the following payment method?</p>

        <PaymentMethodListItem :payment-method="deletingPaymentMethod" />
      </div>
    </ConfirmationModal>
  </div>
</template>

<script setup>
import { ref, computed, onMounted } from 'vue'
import { withStripe } from '@/stripe.js'
import Box from '@/vue_components/Box.vue'
import Table from '@/vue_components/Table.vue'
import Button from '@/vue_components/Button.vue'
import Modal from '@/vue_components/Modal.vue'
import LoadingSpinner from '@/vue_components/LoadingSpinner.vue'
import TextInput from '@/vue_components/TextInput.vue'
import SvgIcon from '@/vue_components/SvgIcon.vue'
import ConfirmationModal from '@/vue_components/ConfirmationModal.vue'
import PaymentMethodListItem from './PaymentMethodListItem.vue'
import AddPaymentMethodModal from './AddPaymentMethodModal.vue'
import TransactionHistory from './TransactionHistory.vue'
import useStore from '../store.js'
import { alert } from '@/alerts.js'

const { state, updatePaymentMethod, deletePaymentMethod, loadPaymentMethods, can } = useStore()

const showAddPaymentModal = ref(false)
const deletingPaymentMethod = ref(null)
const subscription = computed(() => state.subscription)
const paymentMethods = computed(() => state.paymentMethods)
const orders = ref([])

const addPaymentMethod = async () => {
  showAddPaymentModal.value = true
}

const cancelAddPaymentMethod = () => {
  showAddPaymentModal.value = null
}

const onPaymentMethodAdded = async () => {
  alert({ message: 'Payment method added', duration: 3000 })
  await loadPaymentMethods()
  cancelAddPaymentMethod()
}

const onDeletePaymentMethod = async (paymentMethod) => {
  const response = await deletePaymentMethod(paymentMethod.id)

  if (response.ok) {
    alert({ message: 'Payment method removed', duration: 3000 })
    deletingPaymentMethod.value = null
    loadPaymentMethods()
  } else {
    console.error(response)
  }
}

const onMakePaymentMethodDefault = async (paymentMethod) => {
  const response = await updatePaymentMethod(paymentMethod.id, {
    payment_method: {
      is_default: true,
    },
  })

  if (response.ok) {
    alert({ message: 'Default payment method updated', duration: 3000 })
    loadPaymentMethods()
  } else {
    console.error(response)
  }
}
</script>
