<template>
  <div>
    <Form method="post" :action="membership_passes_path()" @submit="handleSubmit" class="space-y-4">
      <div v-if="errors.base" class="bg-red-200 border-2 border-red-500 p-4 rounded-md">
        {{ errors.base }}
      </div>

      <input
        type="hidden"
        name="add_membership_passes_transaction[subscription_id]"
        :value="subscription.id"
      />
      <input
        type="hidden"
        name="add_membership_passes_transaction[membership_pass_type_id]"
        :value="formData.membershipPassType.id"
      />
      <input
        type="hidden"
        name="add_membership_passes_transaction[payment_method_id]"
        :value="formData.paymentMethod.id"
      />
      <input
        type="hidden"
        name="add_membership_passes_transaction[number]"
        :value="formData.number"
      />

      <p class="pr-4">
        You are adding
        <strong>{{ formData.number }} passes</strong>
        at ${{ formData.membershipPassType.price }} per pass ({{ currency(total) }} total). You will
        be charged once immediately and then annually when your subscription renews.
      </p>

      <div class="grid grid-cols-2 gap-4">
        <Button variant="secondary" @click="action('back')">Back</Button>
        <Button variant="primary" type="submit" :disabled="submitting">Purchase Passes</Button>
      </div>
    </Form>
  </div>
</template>

<script setup>
import { ref, computed, inject } from 'vue'
import Box from '@/vue_components/Box.vue'
import Button from '@/vue_components/Button.vue'
import SvgIcon from '@/vue_components/SvgIcon.vue'
import Form from '@/vue_components/Form.vue'
import { membership_passes_path } from '@/routes.js'
import { alert } from '@/alerts.js'
import { submit } from '@/forms.js'
import { currency } from '@/currency.js'
import useStore from '../../store.js'

const { state, loadSubscription } = useStore()

const action = inject('action')
const formData = inject('formData')
const cancel = inject('cancel')

const submitting = ref(false)
const errors = ref({})
const subscription = computed(() => state.subscription)

const total = computed(() => {
  if (!formData.membershipPassType) return 0
  return parseInt(formData.number, 10) * parseInt(formData.membershipPassType.price, 10)
})

const handleSubmit = async ({ form, onError }) => {
  submitting.value = true

  const response = await submit({ form })

  if (response.errors) {
    if (onError) onError()
    errors.value = response.errors
  } else {
    loadSubscription()
    action('next')
  }

  submitting.value = false
}
</script>
