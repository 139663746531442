import { getCSRFToken } from '@/forms.js'

const mergeParams = (...paramSet) => {
  const mergables = ['headers']

  return paramSet.reduce((merged, params) => {
    let next = {
      ...merged,
      ...params,
    }

    for (let param of mergables) {
      if (merged[param] || params[param]) {
        next[param] = {
          ...(merged[param] || {}),
          ...(params[param] || {}),
        }
      }
    }

    return next
  }, {})
}

const applyDefaultParams = (params = {}) => {
  params.method = (params.method || 'GET').toUpperCase()

  if (!params.method || params.method === 'GET' || params.method === 'HEAD') {
    return mergeParams(params, {
      headers: {
        Accept: 'application/json',
      },
      redirect: 'manual',
    })
  } else {
    const csrf = getCSRFToken()
    const contentType =
      params.body instanceof FormData ? {} : { 'Content-Type': 'application/json' }
    const body = params.body instanceof FormData ? params.body : JSON.stringify(params.body)

    return mergeParams(params, {
      redirect: 'manual',
      headers: {
        ...contentType,
        Accept: 'application/json',
        'X-CSRF-Token': csrf.token,
      },
      body,
    })
  }
}

const makeResponse = ({ response, body, error, redirected = false } = {}) => {
  return {
    response,
    body,
    error,
    redirected,
  }
}

const sendRequest = async (url, params = {}) => {
  const attempt = fetch(url, params)
  const response = await attempt

  if (response.redirected) {
    window.location.href = response.headers.get('Location')
    return makeResponse({ response, redirected: true })
  } else if (response.ok) {
    return makeResponse({ response })
  } else {
    let text

    try {
      text = await response.text()
    } catch (e) {
      text = `Error while reading response body: ${e}`
    }

    return makeResponse({ response, error: text })
  }
}

export const request = async (url, params = {}) => {
  return await sendRequest(url, applyDefaultParams(params))
}

export const requestJSON = async (url, params = {}, { followRedirect = true } = {}) => {
  const result = await sendRequest(url, applyDefaultParams(params))

  if (result.error) return result

  let json

  try {
    json = await result.response.json()
  } catch (e) {
    return makeResponse({ response: result.response, error: `Unable to parse JSON: ${e}` })
  }

  if (json.redirect_url) {
    if (followRedirect) window.location.href = json.redirect_url
    return makeResponse({ response: result.response, body: json, redirected: true })
  }

  return makeResponse({ response: result.response, body: json })
}
