import { createApp, h } from 'vue'
import Index from './components/Index.vue'
import useStore from './store.js'

export default ({ el, props = {}, state = {} } = {}) => {
  const { setState } = useStore()

  setState(state)

  const app = createApp({
    render() {
      return h(Index, props)
    },
  })

  return app.mount(el)
}
