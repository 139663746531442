<template>
  <select
    ref="input"
    :name="name"
    class="
      block
      w-full
      p-2
      border border-gray-200 border-solid
      rounded-sm
      shadow-sm
      focus:ring-0 focus:border-gray-300
    "
    :value="modelValue"
    @change="handleInput"
  >
    <optgroup v-if="hasOptionGroups" v-for="group of Object.keys(optionValues)" :label="group">
      <option
        v-for="option of Object.values(optionValues[group])"
        :key="option.value"
        :value="option.value"
      >
        {{ option.display }}
      </option>
    </optgroup>
    <option v-else v-for="option of optionValues" :key="option.value" :value="option.value">
      {{ option.display }}
    </option>
  </select>
</template>

<script setup>
import { ref, computed, onMounted } from 'vue'

const props = defineProps({
  name: String,
  modelValue: String,
  options: {
    type: [Object, Array],
    default: () => [],
  },
})

const emit = defineEmits(['update:modelValue'])

const input = ref(null)

const handleInput = (e) => {
  emit('update:modelValue', e.target.value)
}

const optionValuesFromArray = (options) => {
  return options.map((option) => {
    const isString = typeof option === 'string'
    return {
      display: isString ? option : option.display,
      value: isString ? option : option.value,
    }
  })
}

const hasOptionGroups = computed(() => !Array.isArray(props.options))

const optionValues = computed(() => {
  if (hasOptionGroups.value) {
    return Object.keys(props.options).reduce((all, key) => {
      all[key] = optionValuesFromArray(props.options[key])
      return all
    }, {})
  } else {
    return optionValuesFromArray(props.options)
  }
})
</script>
