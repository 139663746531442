<template>
  <BrandedLayout title="Reset Password" :background="loginBackground">
    <div v-if="!finished">
      <Shaker v-slot="{ shake }">
        <Form
          method="post"
          :action="action"
          @submit="(e) => handleSubmit({ ...e, onError: shake })"
        >
          <div class="space-y-4 lg:space-y-8">
            <div v-if="errors.base" class="bg-red-200 border-2 border-red-500 p-4 rounded-md">
              {{ errors.base }}
            </div>

            <div>
              <label class="text-white">Email</label>
              <TextInput type="text" name="password_reset_request[email]" />
              <FieldError :error="errors.password_reset_request?.email" />
            </div>

            <div class="flex items-center space-x-4">
              <Button type="submit" variant="branded" :disabled="submitting">Reset password</Button>
              <a :href="login_path()" class="text-white">Back</a>
            </div>
          </div>
        </Form>
      </Shaker>
    </div>
    <div v-else>
      <div class="space-y-4 lg:space-y-8 text-white">
        <h1 class="text-xl">✅ Done!</h1>

        <p>An email has been sent with instuctions on how to reset your password.</p>

        <div class="flex items-center space-x-4">
          <Button variant="branded" :href="login_path()">Back to login</Button>
        </div>
      </div>
    </div>
  </BrandedLayout>
</template>

<script setup>
import { ref } from 'vue'
import BrandedLayout from '@/vue_components/BrandedLayout.vue'
import Button from '@/vue_components/Button.vue'
import Shaker from '@/vue_components/Shaker.vue'
import Form from '@/vue_components/Form.vue'
import TextInput from '@/vue_components/TextInput.vue'
import FieldError from '@/vue_components/FieldError.vue'
import { login_path, password_resets_path } from '@/routes.js'
import { submit } from '@/forms.js'

import loginBackground from '@/images/login-background.jpg'

const action = password_resets_path()
const errors = ref({})
const submitting = ref(false)
const finished = ref(false)

const handleSubmit = async ({ form, onError }) => {
  submitting.value = true

  const response = await submit({ form })

  if (response.errors) {
    if (onError) onError()
    errors.value = response.errors
  } else {
    finished.value = true
  }

  submitting.value = false
}
</script>
