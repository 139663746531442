<template>
  <div>
    <div class="h-64 overflow-y-auto border-b border-gray-200 space-y-4">
      <Box v-for="(parent, index) of formData.parents" :key="parent.key" class="relative space-y-2">
        <p class="font-light text-gray-500">Parent {{ index + 1 }}</p>

        <button
          v-if="canRemoveParents"
          type="button"
          class="absolute top-2 right-4 inline-flex items-center text-red-500 space-x-1"
          @click="removeParentAtIndex(index)"
        >
          <SvgIcon icon="trash" size="h-5" />
          <span>Remove</span>
        </button>

        <div class="grid grid-cols-2 gap-4">
          <div>
            <label>First Name</label>
            <TextInput v-model="formData.parents[index].first_name" />
            <FieldError :error="parentErrors(index)?.first_name" />
          </div>

          <div>
            <label>Last Name</label>
            <TextInput v-model="formData.parents[index].last_name" />
            <FieldError :error="parentErrors(index)?.last_name" />
          </div>

          <div>
            <label>Relationship</label>
            <TextInput v-model="formData.parents[index].relationship" />
            <FieldError :error="parentErrors(index)?.relationship" />
          </div>

          <div>
            <label>Email</label>
            <TextInput v-model="formData.parents[index].email" />
            <FieldError :error="parentErrors(index)?.email" />
          </div>

          <div>
            <label>Cell Phone</label>
            <PhoneNumberInput v-model="formData.parents[index].cell_phone" />
            <FieldError :error="parentErrors(index)?.cell_phone" />
          </div>
        </div>
      </Box>
    </div>

    <div class="p-4 text-center">
      <button
        type="button"
        @click="addParent"
        class="inline-flex items-center space-x-2 font-bold text-blue-500"
      >
        <SvgIcon icon="add-outline" />
        <span>Add Another Parent</span>
      </button>
    </div>

    <div class="grid grid-cols-2 gap-4 pt-4">
      <Button variant="secondary" @click="action('back')">Back</Button>
      <Button variant="primary" @click="next">
        <span class="inline-block mr-1">Mentor</span>
        <SvgIcon icon="cheveron-right" />
      </Button>
    </div>
  </div>
</template>

<script setup>
import { computed, ref, inject } from 'vue'
import Box from '@/vue_components/Box.vue'
import Button from '@/vue_components/Button.vue'
import TextInput from '@/vue_components/TextInput.vue'
import PhoneNumberInput from '@/vue_components/PhoneNumberInput.vue'
import SelectInput from '@/vue_components/SelectInput.vue'
import FieldError from '@/vue_components/FieldError.vue'
import SvgIcon from '@/vue_components/SvgIcon.vue'
import { validate_membership_invitations_path } from '@/routes.js'
import { requestJSON } from '@/requests.js'
import useStore from '../../store.js'

const { state } = useStore()
const formData = inject('formData')
const submissionErrors = inject('errors')
const action = inject('action')
const validationErrors = ref({})

const addParent = () => {
  formData.value.parents.push({
    key: performance.now(),
    subscription_id: state.subscription.id,
  })
}

const removeParentAtIndex = (index) => {
  const parents = [...formData.value.parents]
  parents.splice(index, 1)
  formData.value.parents = parents
}

const canRemoveParents = computed(() => {
  return formData.value.parents && formData.value.parents.length > 1
})

const errors = computed(() => ({
  parents: {
    ...(submissionErrors.value.parents || {}),
    ...(validationErrors.value.parents || {}),
  },
}))

const parentErrors = (index) => {
  const parents = errors.value.parents || []
  return parents ? parents[index]?.membership : {}
}

const validate = async () => {
  validationErrors.value = {}

  const { body } = await requestJSON(validate_membership_invitations_path(), {
    method: 'post',
    body: { membership: formData.value },
  })

  const errorsForParents = body.errors?.parents || []
  const hasInvalidParent = errorsForParents.some(
    (parent) => Object.keys(parent.membership).length > 0,
  )

  if (hasInvalidParent) {
    validationErrors.value = body.errors
    return false
  } else {
    return true
  }
}

const next = async () => {
  const valid = await validate()

  if (valid) {
    action('next')
  }
}
</script>
