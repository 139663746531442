import { createApp, h } from 'vue'
import { createRouter, createWebHistory } from 'vue-router'
import Show from './components/Show.vue'
import Members from './components/Members.vue'
import Mentors from './components/Mentors.vue'
import Subscription from './components/Subscription.vue'
import Billing from './components/Billing.vue'
import Organization from './components/Organization.vue'
import useStore from './store.js'

export default ({ el, props = {}, state = {} } = {}) => {
  const routes = [
    { path: '/', redirect: '/members' },
    { path: '/members', component: Members },
    { path: '/mentors', component: Mentors },
    { path: '/subscription', component: Subscription },
    { path: '/billing', component: Billing },
    { path: '/organization', component: Organization },
  ]

  const router = createRouter({
    history: createWebHistory(`/subscriptions/${state.subscription.id}`),
    routes,
  })

  const { setState, loadPaymentMethods, loadMembershipPassTypes } = useStore()

  setState(state)

  loadPaymentMethods()
  loadMembershipPassTypes()

  const app = createApp({
    render() {
      return h(Show, props)
    },
  })

  app.use(router)

  return app.mount(el)
}
