<template>
  <BrandedLayout title="Login" :background="loginBackground">
    <Shaker v-slot="{ shake }">
      <Form
        method="post"
        :action="login_path()"
        @submit="
          (e) => {
            handleSubmit({ ...e, onError: shake })
          }
        "
      >
        <input type="hidden" name="login_attempt[return_to]" :value="returnTo" />

        <div class="space-y-4 lg:space-y-8">
          <div v-if="errors.base" class="bg-red-200 border-2 border-red-500 p-4 rounded-md">
            {{ errors.base }}
          </div>

          <div>
            <label class="text-white">Email</label>
            <TextInput type="text" name="login_attempt[email]" />
            <FieldError :error="errors.login_attempt?.email" />
          </div>

          <div>
            <label class="text-white">Password</label>
            <TextInput type="password" name="login_attempt[password]" />
            <FieldError :error="errors.login_attempt?.password" />
          </div>

          <div class="flex items-center space-x-4">
            <Button type="submit" variant="branded" :disabled="submitting">Log In</Button>
            <a :href="new_password_reset_path()" class="text-white">Forgot your password?</a>
          </div>
        </div>
      </Form>
    </Shaker>
  </BrandedLayout>
</template>

<script setup>
import { ref } from 'vue'
import BrandedLayout from '@/vue_components/BrandedLayout.vue'
import Button from '@/vue_components/Button.vue'
import Shaker from '@/vue_components/Shaker.vue'
import Box from '@/vue_components/Box.vue'
import Form from '@/vue_components/Form.vue'
import TextInput from '@/vue_components/TextInput.vue'
import FieldError from '@/vue_components/FieldError.vue'
import { root_path, login_path, new_password_reset_path } from '@/routes.js'
import { submit } from '@/forms.js'

import loginBackground from '@/images/login-background.jpg'

const props = defineProps({
  returnTo: {
    type: String,
  },
})

const errors = ref({})
const submitting = ref(false)

const handleSubmit = async ({ form, onError }) => {
  submitting.value = true

  const response = await submit({ form })

  if (response.errors) {
    if (onError) onError()
    errors.value = response.errors
  } else {
    window.location.href = response.redirect_url || root_path()
  }

  submitting.value = false
}
</script>
