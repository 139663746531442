<template>
  <BrandedLayout :title="title" :background="loginBackground">
    <div class="text-white">
      <div v-if="!membership">
        <SvgIcon icon="exclamation-outline" class="h-12 text-red-500 mb-4" />
        <p class="font-bold text-xl">Uh oh!</p>
        <p class="mt-4">That invitation link is no longer valid.</p>
      </div>

      <div v-else-if="!membership.active">
        <SvgIcon icon="exclamation-outline" class="h-12 text-red-500 mb-4" />
        <p class="font-bold text-xl">Uh oh!</p>
        <p class="mt-4">There was a problem activating your membership. Please try again later.</p>
      </div>

      <div v-else>
        <div class="text-4xl mb-4">🎉</div>
        <p class="font-bold text-xl">Congratulations!</p>
        <p class="mt-4">
          Your INFLUNSR membership is now active. You will now receive regular content drops.
        </p>
      </div>
    </div>
  </BrandedLayout>
</template>

<script setup>
import { computed } from 'vue'
import BrandedLayout from '@/vue_components/BrandedLayout.vue'
import SvgIcon from '@/vue_components/SvgIcon.vue'

import loginBackground from '@/images/login-background.jpg'

const props = defineProps({
  membership: {
    type: Object,
  },
})

const title = computed(() => {
  if (props.membership && props.membership.active) {
    return 'Welcome!'
  } else {
    return 'Uh oh...'
  }
})
</script>
