import { createApp, h } from 'vue'
import Login from './components/Login.vue'

export default ({ el, props }) => {
  const app = createApp({
    render() {
      return h(Login, props)
    },
  })

  return app.mount(el)
}
