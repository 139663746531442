<template>
  <div class="divide-y">
    <div class="p-4">
      <TextInput placeholder="Type or choose a mentor's name" v-model="filterString" autofocus />
    </div>

    <div class="overflow-auto divide-y h-48">
      <button
        v-for="mentor in filteredMentorOptions"
        :key="mentor.id"
        type="button"
        class="w-full px-4 py-2 text-left hover:bg-blue-100 hover:text-gray-700"
        :class="mentorClasses(mentor)"
        @click="emit('chooseMentor', mentor)"
      >
        {{ mentor.display }}
      </button>
    </div>
  </div>
</template>

<script setup>
import { ref, computed, onMounted } from 'vue'
import TextInput from '@/vue_components/TextInput.vue'
import Box from '@/vue_components/Box.vue'

const props = defineProps({
  mentors: Array,
  selectedMentorId: Number,
  highlightSelected: {
    type: Boolean,
    default: false,
  },
})

const emit = defineEmits(['chooseMentor'])

const filterString = ref('')

const unassignedMentor = { id: null, display: 'No mentor' }

const mentorOptions = computed(() => [
  unassignedMentor,
  ...props.mentors.map((mentor) => ({
    id: mentor.id,
    display: mentor.full_name,
  })),
])

const filteredMentorOptions = computed(() =>
  mentorOptions.value.filter((mentor) =>
    mentor.display.toLowerCase().startsWith(filterString.value.toLowerCase()),
  ),
)

const mentorClasses = (mentor) => {
  return {
    'italic text-gray-500': !mentor.id,
    'bg-blue-500 text-white font-bold':
      props.highlightSelected && props.selectedMentorId == mentor.id,
  }
}
</script>
