<template>
  <Modal :content-size="setupIntent ? 'full' : 'content'">
    <form v-if="setupIntent" class="space-y-4" @submit="onSavePaymentMethod">
      <p class="flex items-center text-xl font-bold space-x-2">
        <SvgIcon icon="credit-card" />
        <span>Add new payment method</span>
      </p>

      <div>
        <label>Name on card</label>
        <TextInput name="name" />
      </div>

      <div class="flex space-x-4">
        <div class="flex-2">
          <label>Card Number</label>
          <div id="stripe-card-number"></div>
        </div>

        <div class="flex-1">
          <label>Exp Date</label>
          <div id="stripe-card-expiry"></div>
        </div>

        <div class="flex-1">
          <label>CVC</label>
          <div id="stripe-card-cvc"></div>
        </div>
      </div>

      <div class="grid grid-cols-2 gap-4">
        <div>
          <label>Billing Zip Code</label>
          <TextInput name="zip" />
        </div>
      </div>

      <div>
        <label class="inline-flex items-center space-x-2">
          <input type="checkbox" name="is_default" value="1" checked />
          <span>Make default?</span>
        </label>
      </div>

      <div class="flex items-center space-x-2">
        <Button type="submit" :disabled="submitting">
          <LoadingSpinner v-if="submitting" size="small" class="mr-2 text-gray-400" />
          <span class="inline-block">Save Payment Method</span>
        </Button>
        <Button type="button" variant="secondary" :disabled="submitting" @click="onCancel">
          Cancel
        </Button>
      </div>
    </form>

    <div v-else class="flex justify-center p-8">
      <LoadingSpinner class="text-blue-500" />
    </div>
  </Modal>
</template>

<script setup>
import { ref, computed, onMounted } from 'vue'
import { withStripe } from '@/stripe.js'
import Button from '@/vue_components/Button.vue'
import Modal from '@/vue_components/Modal.vue'
import LoadingSpinner from '@/vue_components/LoadingSpinner.vue'
import TextInput from '@/vue_components/TextInput.vue'
import SvgIcon from '@/vue_components/SvgIcon.vue'
import useStore from '../store.js'

const emit = defineEmits(['added', 'cancel'])

const { state, buildPaymentMethod, createPaymentMethod } = useStore()

const submitting = ref(false)
const setupIntent = ref(null)
const cardNumberElement = ref(null)
const cardExpiryElement = ref(null)
const cardCvcElement = ref(null)

onMounted(async () => {
  setupIntent.value = await buildPaymentMethod()

  withStripe((stripe) => {
    const elements = stripe.elements()

    cardNumberElement.value = elements.create('cardNumber')
    cardExpiryElement.value = elements.create('cardExpiry')
    cardCvcElement.value = elements.create('cardCvc')

    cardNumberElement.value.mount('#stripe-card-number')
    cardExpiryElement.value.mount('#stripe-card-expiry')
    cardCvcElement.value.mount('#stripe-card-cvc')
  })
})

const onCancel = () => {
  setupIntent.value = null
  emit('cancel')
}

const onSavePaymentMethod = (e) => {
  e.preventDefault()

  submitting.value = true

  const form = e.target
  const formData = new FormData(form)

  withStripe(async (stripe) => {
    try {
      const result = await stripe.confirmCardSetup(setupIntent.value.client_secret, {
        payment_method: {
          card: cardNumberElement.value,
          billing_details: {
            name: formData.get('name'),
            address: {
              postal_code: formData.get('zip'),
            },
          },
        },
      })

      if (result.error) {
        console.error(result.error)
      } else {
        const isDefault = formData.get('is_default') == '1'
        const { response, body } = await createPaymentMethod(result.setupIntent.payment_method, {
          isDefault,
        })

        if (response.ok) {
          emit('added')
        } else {
          console.error(body)
        }
      }
    } catch (e) {
      console.error(e)
    }

    submitting.value = false
  })
}
</script>
