<template>
  <template v-if="href">
    <a :href="href" :class="classes" ref="self">
      <slot></slot>
    </a>
  </template>
  <template v-else>
    <button :type="type" :disabled="disabled" :class="classes" ref="self">
      <slot></slot>
    </button>
  </template>
</template>

<script>
import { computed, ref } from 'vue'

export default {
  props: {
    type: {
      type: String,
      default: 'button',
    },
    variant: {
      type: String,
      default: 'primary',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    href: {
      type: String,
    },
  },
  setup(props) {
    const self = ref()

    const baseClasses = 'inline-flex items-center justify-center rounded-md'
    const paddingClasses = 'py-2 px-6'
    const disabledClasses = 'bg-gray-200 text-gray-800 cursor-not-allowed font-bold'
    const primaryClasses = 'bg-blue-500 text-white font-bold'
    const borderedClasses = 'border border-blue-500 text-blue-500 font-bold'
    const secondaryClasses = 'bg-white border border-gray-300'
    const linkClasses = 'text-blue-500 p-0'
    const brandedClasses =
      'bg-white text-black font-black uppercase rounded-none font-brand-heading'
    const brandedDarkClasses =
      'bg-white text-black font-black uppercase rounded-none font-brand-heading border-4 border-black'
    const dangerClasses = 'bg-red-500 text-white font-bold'

    const classes = computed(() => ({
      [baseClasses]: true,
      [paddingClasses]: props.variant !== 'link',
      [primaryClasses]: !props.disabled && props.variant === 'primary',
      [secondaryClasses]: !props.disabled && props.variant === 'secondary',
      [borderedClasses]: !props.disabled && props.variant === 'bordered',
      [dangerClasses]: !props.disabled && props.variant === 'danger',
      [linkClasses]: !props.disabled && props.variant === 'link',
      [brandedClasses]: props.variant === 'branded',
      [brandedDarkClasses]: props.variant === 'brandedDark',
      [disabledClasses]: props.disabled,
    }))

    const focus = () => {
      self.value.focus()
    }

    return {
      classes,
      self,
      focus,
    }
  },
}
</script>
