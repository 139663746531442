import { ref } from 'vue'

export const useStateMachine = ({ states, initialState, watchState }) => {
  const currentState = ref(null)

  const onStateChange = watchState || (() => {})

  const getState = (name) => {
    return states[name]
  }

  const goto = (state) => {
    const fromState = getState(currentState.value)
    const toState = getState(state)

    if (fromState && fromState.onExit) fromState.onExit({ toState: state })
    if (toState && toState.onEnter) toState.onEnter({ fromState: currentState.value })

    onStateChange({ fromState: currentState.value, toState: state })

    currentState.value = state
  }

  const action = (name, params = {}) => {
    const state = getState(currentState.value)
    const action = state.actions[name] || (() => {})
    let calledGoto = null

    const gotoProxy = (state) => {
      if (calledGoto) {
        throw `"goto" has already been called by this action with "${calledGoto}" - it was called with "${state}" this time`
      }

      calledGoto = state
    }

    action({ goto: gotoProxy, params })

    if (calledGoto) {
      goto(calledGoto)
    }
  }

  goto(initialState || Object.keys(states)[0])

  return {
    currentState,
    action,
  }
}
