<template>
  <div :class="classes">
    <slot></slot>
  </div>
</template>

<script setup>
import { computed } from 'vue'

const props = defineProps({
  variant: {
    type: String,
    default: 'notice',
  },
})

const variantClasses = {
  notice: ['p-4 bg-gray-900 text-white'],
  alert: ['p-4 bg-yellow-600 text-white'],
  danger: ['p-4 bg-red-800 text-white'],
}

const classes = computed(() => {
  return variantClasses[props.variant] || variantClasses['notice']
})
</script>
