<template>
  <div v-if="subscriptionType" class="space-y-4 lg:space-y-8">
    <h2 class="text-xl font-light uppercase text-center space-y-2">
      <SvgIcon icon="calendar" />
      <div>Set up your subscription</div>
    </h2>

    <div class="space-y-4 lg:space-y-8">
      <Box class="grid grid-cols-2 grid-wrap items-center gap-2">
        <div class="col-span-2">
          <p class="text-gray-500">Subscription</p>
          <p class="font-bold text-xl">
            {{ subscriptionType.name }} &mdash; {{ currency(subscriptionType.price) }} / year
          </p>
          <p class="text-gray-500 text-sm">+ {{ currency(membershipPassType.price) }} / pass</p>
        </div>

        <div>Student Annual Passes</div>

        <div v-if="subscriptionType.free_trial_duration" class="text-right">
          {{
            pluralize('starting pass', state.formData.subscription.number_of_memberships, {
              inclusive: true,
              suffix: 'es',
            })
          }}
        </div>
        <div v-else class="text-right">
          <div class="inline-flex items-center space-x-4">
            <div class="text-right">
              <button @mousedown="startDecreasingNumber">
                <SvgIcon icon="minus-solid"></SvgIcon>
              </button>
            </div>

            <div class="border border-gray-400 rounded-sm text-center w-16 py-2">
              <div class="font-bold">
                {{ state.formData.subscription.number_of_memberships }}
              </div>
            </div>

            <div class="text-left">
              <button @mousedown="startIncreasingNumber">
                <SvgIcon icon="add-solid"></SvgIcon>
              </button>
            </div>
          </div>
        </div>

        <div class="col-span-2 py-2">
          <hr />
        </div>

        <div>Total</div>

        <div class="text-right space-y-2">
          <span class="inline-block">
            {{ currency(total) }}
            <div class="text-gray-500 text-xs">/ per year</div>
          </span>

          <div v-if="subscriptionType.free_trial_duration">
            <span class="px-4 py-2 bg-pink-500">
              Free for {{ subscriptionType.free_trial_duration }} months
            </span>
          </div>
        </div>
      </Box>

      <p v-if="subscriptionType.free_trial_duration" class="px-4 text-gray-500 text-center">
        At the end of your free trial, we will automatically process payment for your subscription.
      </p>
      <p v-else class="px-4 text-gray-500 text-center">
        Your subscription requires a minimum of
        {{ subscriptionType.membership_pass_increment }} student annual passes, but you can add as
        many as you'd like.
      </p>
    </div>

    <div class="grid grid-cols-2 gap-4 pt-4">
      <Button variant="secondary" @click="back">
        <SvgIcon icon="cheveron-left" />
        <span class="inline-block ml-1">Back</span>
      </Button>

      <Button variant="primary" @click="next">
        <span class="inline-block mr-1">Next</span>
        <SvgIcon icon="cheveron-right" />
      </Button>
    </div>
  </div>
</template>

<script setup>
import { ref, computed, onMounted, onUnmounted } from 'vue'
import Box from '@/vue_components/Box.vue'
import TextInput from '@/vue_components/TextInput.vue'
import SvgIcon from '@/vue_components/SvgIcon.vue'
import Button from '@/vue_components/Button.vue'
import { currency } from '@/currency.js'
import { pluralize } from '@/inflections.js'
import useStore from '../store.js'

const emit = defineEmits(['next', 'back'])

const { state } = useStore()

const subscription = computed(() => state.formData.subscription)
const subscriptionTypes = computed(() => state.subscription_types)
const subscriptionType = computed(() =>
  subscriptionTypes.value.find((type) => type.id == subscription.value.subscription_type_id),
)
const membershipPassType = computed(() => subscriptionType.value.default_membership_pass_type)

const stepper = ref(null)
const delta = ref(0)
const deltaGap = ref(1000)
const start = ref(performance.now())

const total = computed(
  () =>
    subscriptionType.value.price +
    membershipPassType.value.price * subscription.value.number_of_memberships,
)

const changeNumber = (delta) => {
  subscription.value.number_of_memberships = Math.max(
    subscriptionType.value.membership_pass_increment,
    subscription.value.number_of_memberships +
      delta * subscriptionType.value.membership_pass_increment,
  )
}

const startIncreasingNumber = () => {
  changeNumber(1)
  delta.value = 1
  deltaGap.value = 1000
  start.value = performance.now()
}

const stopIncreasingNumber = () => {
  delta.value = 0
}

const startDecreasingNumber = () => {
  changeNumber(-1)
  delta.value = -1
  deltaGap.value = 1000
  start.value = performance.now()
}

const stopDecreasingNumber = () => {
  delta.value = 0
}

function step(timestamp) {
  const elapsed = timestamp - start.value

  if (delta.value != 0) {
    if (elapsed > deltaGap.value) {
      start.value = timestamp - (elapsed - deltaGap.value)
      deltaGap.value = Math.max(100, deltaGap.value * 0.65)
      changeNumber(delta.value)
    }
  }

  window.requestAnimationFrame(step)
}

onMounted(() => {
  stepper.value = requestAnimationFrame(step)
  window.addEventListener('mouseup', () => {
    stopIncreasingNumber()
    stopDecreasingNumber()
  })
})

onUnmounted(() => {
  if (stepper.value) {
    cancelAnimationFrame(stepper.value)
    stepper.value = null
  }
})

const back = () => {
  emit('back')
}

const next = () => {
  emit('next')
}
</script>
