<template>
  <div class="space-y-4 lg:space-y-8">
    <h2 class="text-xl font-light uppercase text-center space-y-2">
      <SvgIcon icon="question" />
      <div>Choose a subscription type</div>
    </h2>

    <div class="grid grid-cols-1 sm:grid-cols-2 gap-4">
      <Box
        v-for="subscriptionType of subscriptionTypes"
        :key="subscriptionType.id"
        class="flex flex-col justify-center space-y-4 cursor-pointer"
        :class="subscriptionTypeClasses(subscriptionType)"
        @click="selectSubscriptionType(subscriptionType)"
      >
        <h2 class="text-center font-brand-heading font-bold uppercase text-xl">
          {{ subscriptionType.name }}
        </h2>
        <p class="text-center font-brand-heading text-4xl font-bold">
          <span>{{ currency(subscriptionType.price) }}</span>
          <span class="text-lg font-light">/ year</span>
        </p>
        <p class="text-center font-light uppercase px-4 text-gray-500 text-sm">
          {{ subscriptionType.description }}
        </p>
        <div v-if="subscriptionType.free_trial_duration" class="text-center">
          <span class="px-4 py-2 bg-pink-500">
            {{ subscriptionType.free_trial_duration }} month free trial
          </span>
        </div>
      </Box>
    </div>

    <div class="grid grid-cols-2 gap-4 pt-4">
      <div></div>
      <Button variant="primary" @click="next" :disabled="!subscriptionType">
        <span class="inline-block mr-1">Next</span>
        <SvgIcon icon="cheveron-right" />
      </Button>
    </div>
  </div>
</template>

<script setup>
import { ref, computed } from 'vue'
import Box from '@/vue_components/Box.vue'
import TextInput from '@/vue_components/TextInput.vue'
import FieldError from '@/vue_components/FieldError.vue'
import PhoneNumberInput from '@/vue_components/PhoneNumberInput.vue'
import SelectInput from '@/vue_components/SelectInput.vue'
import SvgIcon from '@/vue_components/SvgIcon.vue'
import Button from '@/vue_components/Button.vue'
import { stateCodes } from '@/country_states.js'
import { currency } from '@/currency.js'
import { denominations, sizeOptions } from '@/church_information.js'
import { requestJSON } from '@/requests.js'
import useStore from '../store.js'

const emit = defineEmits(['next'])

const { state } = useStore()
const subscriptionTypes = computed(() => state.subscription_types)
const subscriptionType = ref(null)

const subscriptionTypeClasses = (thisSubscriptionType) => {
  if (subscriptionType.value === thisSubscriptionType) {
    return ['border-transparent ring-4 ring-blue-500']
  } else {
    return ['']
  }
}

const selectSubscriptionType = (selectedSubscriptionType) => {
  subscriptionType.value = selectedSubscriptionType
}

const next = async () => {
  state.formData.subscription.subscription_type_id = subscriptionType.value.id
  state.formData.subscription.number_of_memberships =
    subscriptionType.value.membership_pass_increment
  state.formData.owner.job_title = subscriptionType.value.category === 'family' ? 'Parent' : null
  emit('next')
}
</script>
