<template>
  <div class="inline-block rounded-full animate-spin" :class="dynamicClasses" :style="spinnerStyle"></div>
</template>

<script>
import { computed } from 'vue'

const SPINNER_WIDTHS = {
  small: '3px',
  medium: '0.75rem',
}

export default {
  props: {
    size: {
      type: String,
      default: 'medium',
    },
  },
  setup(props) {
    const spinnerWidth = computed(() => SPINNER_WIDTHS[props.size])

    const spinnerStyle = computed(() => ({
      border: `${spinnerWidth.value} solid transparent`,
      borderTopColor: 'currentColor',
    }))

    const dynamicClasses = {
      'w-6 h-6': props.size == 'small',
      'w-20 h-20': props.size == 'medium',
    }

    return {
      dynamicClasses,
      spinnerStyle,
    }
  },
}
</script>
