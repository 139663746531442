<template>
  <Modal @keydown.esc.capture="$emit('deny')">
    <div class="space-y-4">
      <div>
        <slot>{{ title }}</slot>
      </div>

      <div class="grid grid-cols-2 gap-4">
        <Button variant="primary" @click="$emit('confirm')">{{ confirmText }}</Button>
        <Button ref="denyButton" variant="secondary" @click="$emit('deny')">{{ denyText }}</Button>
      </div>
    </div>
  </Modal>
</template>

<script>
import { onMounted, ref } from 'vue'
import Modal from '@/vue_components/Modal.vue'
import Button from '@/vue_components/Button.vue'

export default {
  components: {
    Modal,
    Button,
  },
  emits: ['confirm', 'deny'],
  props: {
    title: {
      type: String,
      default: '',
    },
    confirmText: {
      type: String,
      default: 'Yes',
    },
    denyText: {
      type: String,
      default: 'No',
    },
  },
  setup(props) {
    const denyButton = ref()

    onMounted(() => {
      denyButton.value.focus()
    })

    return {
      denyButton,
    }
  },
}
</script>
