<template>
  <div class="space-y-4">
    <Box class="relative">
      <div class="space-y-4">
        <div>
          <p class="font-bold text-xl">{{ formData.membershipPassType.name }}</p>
          <p>${{ formData.membershipPassType.price }} / pass</p>
        </div>

        <div class="flex items-center justify-center space-x-4">
          <div :style="{ 'flex-grow': 1, 'flex-shrink': 0 }" class="text-right">
            <button @click="decreaseNumber"><SvgIcon icon="minus-solid"></SvgIcon></button>
          </div>

          <input
            type="text"
            :style="{ 'flex-grow': 2 }"
            class="border border-gray-400 rounded-sm text-center text-4xl font-bold py-2"
            :value="number"
            readonly
          />

          <div :style="{ 'flex-grow': 1, 'flex-shrink': 0 }" class="text-left">
            <button @click="increaseNumber"><SvgIcon icon="add-solid"></SvgIcon></button>
          </div>
        </div>
      </div>
    </Box>

    <div class="flex items-center space-x-2 justify-center">
      <span>${{ formData.membershipPassType.price }} / pass &times; {{ number }} =</span>
      <span class="font-bold text-2xl">${{ total }}</span>
    </div>

    <div class="grid grid-cols-2 gap-4">
      <Button variant="secondary" @click="action('back')">Back</Button>
      <Button variant="primary" @click="selectNumber">Next</Button>
    </div>
  </div>
</template>

<script setup>
import { ref, computed, inject } from 'vue'
import Box from '@/vue_components/Box.vue'
import Button from '@/vue_components/Button.vue'
import SvgIcon from '@/vue_components/SvgIcon.vue'
import useStore from '../../store.js'

const action = inject('action')
const formData = inject('formData')
const cancel = inject('cancel')

const { state } = useStore()
const number = ref(state.subscription.subscription_type.membership_pass_increment)

const increaseNumber = () => {
  number.value += state.subscription.subscription_type.membership_pass_increment
}

const decreaseNumber = () => {
  number.value = Math.max(
    state.subscription.subscription_type.membership_pass_increment,
    number.value - state.subscription.subscription_type.membership_pass_increment,
  )
}

const total = computed(() => {
  if (!formData.membershipPassType) return 0
  return parseInt(number.value, 10) * parseInt(formData.membershipPassType.price, 10)
})

const selectNumber = () => {
  action('next', { number: number.value })
}
</script>
