<template>
  <div class="min-h-screen lg:flex" :class="themeClasses">
    <div
      class="flex-none p-20 lg:w-[33.33%]"
      :style="{
        background: `linear-gradient(rgba(255, 255, 255, 0.8), rgba(255, 255, 255, 0.8)), url(${background})`,
        backgroundSize: 'cover',
        backgroundPositionX: '71%',
        backgroundPositionY: 'center',
      }"
    >
      <div class="relative">
        <h1
          class="
            text-5xl
            lg:text-3xl
            xl:text-5xl
            text-black
            uppercase
            font-brand-heading font-black
            lg:text-right
          "
        >
          {{ title }}
        </h1>
        <div class="relative h-1 bg-black lg:w-[200%] lg:right-[100%]"></div>
      </div>
    </div>

    <div class="flex-grow p-6 lg:p-10">
      <div class="xl:w-3/4 lg:pt-8">
        <slot></slot>
      </div>
    </div>
  </div>
</template>

<script setup>
import { computed } from 'vue'

const props = defineProps({
  background: String,
  title: String,
  theme: {
    type: String,
    default: 'dark',
  },
})

const contentBackgroundColor = computed(() => (props.theme === 'dark' ? 'bg-black' : 'bg-white'))
const contentTextColor = computed(() => (props.theme === 'dark' ? 'text-white' : 'text-black'))
const themeClasses = computed(() => [contentBackgroundColor.value, contentTextColor.value])
</script>
