<template>
  <div class="w-1/2 mx-auto my-8">
    <Box>
      <Welcome v-show="currentStep === 'welcome'" @next="nextAfterWelcome" />
      <AddPaymentMethod v-show="currentStep === 'addPaymentMethod'" @next="nextAfterAddPayment" />
      <ConfirmPayment v-show="currentStep === 'confirmPayment'" />
    </Box>
  </div>
</template>

<script setup>
import { watch, ref, computed, reactive, provide } from 'vue'
import Welcome from './Welcome.vue'
import AddPaymentMethod from './AddPaymentMethod.vue'
import ConfirmPayment from './ConfirmPayment.vue'
import Box from '@/vue_components/Box.vue'
import Button from '@/vue_components/Button.vue'
import TextInput from '@/vue_components/TextInput.vue'
import FieldError from '@/vue_components/FieldError.vue'
import SvgIcon from '@/vue_components/SvgIcon.vue'
import useStore from '../../store.js'

const { state, loadSubscription } = useStore()
const subscription = computed(() => state.subscription)
const paymentMethods = computed(() => state.paymentMethods)
const paymentMethod = computed(() =>
  state.paymentMethods.length > 0 ? state.paymentMethods[0] : null,
)

provide('paymentMethod', paymentMethod)

const currentStep = ref('welcome')

const nextAfterWelcome = () => {
  if (paymentMethods.value.length > 0) {
    currentStep.value = 'confirmPayment'
  } else {
    currentStep.value = 'addPaymentMethod'
  }
}

const nextAfterAddPayment = () => {
  if (subscription.value.free_trial_active) {
    loadSubscription()
  } else {
    currentStep.value = 'confirmPayment'
  }
}
</script>
