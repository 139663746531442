<template>
  <Modal content-size="full">
    <div class="flex flex-col max-h-[800px] space-y-6">
      <div
        class="flex-grow overflow-auto space-y-6 border-b border-gray-200 pb-6"
        ref="scrollContainer"
      >
        <div class="space-y-4">
          <h2 class="font-bold uppercase text-lg">Student Information</h2>
          <Student />
        </div>

        <div class="space-y-4">
          <h2 class="font-bold uppercase text-lg">Parents</h2>
          <Parents />
        </div>

        <div class="space-y-4">
          <h2 class="font-bold uppercase text-lg">Mentor</h2>
          <Mentor />
        </div>
      </div>

      <div class="flex-none">
        <div class="grid grid-cols-2 gap-4">
          <Button variant="secondary" @click="close">Cancel</Button>
          <Button variant="primary" @click="submit">Update</Button>
        </div>
      </div>
    </div>
  </Modal>
</template>

<script setup>
import { ref, computed, provide, onMounted } from 'vue'
import Modal from '@/vue_components/Modal.vue'
import ProgressBreadcrumbs from '@/vue_components/ProgressBreadcrumbs.vue'
import Button from '@/vue_components/Button.vue'
import SvgIcon from '@/vue_components/SvgIcon.vue'
import Student from './Student.vue'
import Parents from './Parents.vue'
import Mentor from './Mentor.vue'
import { useStateMachine } from '@/state_machine.js'
import { membership_path } from '@/routes.js'
import useStore from '../../store.js'
import { requestJSON } from '@/requests.js'

const props = defineProps({
  membership: {
    type: Object,
    default: null,
  },
})

const emit = defineEmits(['cancel', 'close'])

const { state, loadSubscription } = useStore()
const subscription = computed(() => state.subscription)

const formData = ref({
  subscription_id: subscription.value.id,
  first_name: props.membership.first_name,
  last_name: props.membership.last_name,
  email: props.membership.email,
  cell_phone: props.membership.cell_phone,
  school: props.membership.school,
  grade_level: props.membership.grade_level,
  parents: props.membership.parents,
  mentor_relationship_attributes: { mentor_id: props.membership.mentor?.id },
})
const submitting = ref(false)
const errors = ref({})
const scrollContainer = ref(null)

const close = () => {
  emit('close')
}

const submit = async () => {
  submitting.value = true

  const { body } = await requestJSON(membership_path(props.membership.id), {
    method: 'PATCH',
    body: { membership: formData.value },
  })

  submitting.value = false

  if (body.errors) {
    errors.value = body.errors
  } else {
    await loadSubscription()
    emit('close')
  }
}

onMounted(() => {
  scrollContainer.value.scrollTop = 0
})

provide('membership', props.membership)
provide('formData', formData)
provide('errors', errors)
</script>
