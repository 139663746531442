import { useState } from '@/states.js'

const { state, setState } = useState({
  alerts: [],
})

let processingRemovals = false
let removing = []

const addAlert = (alert) => {
  let toAdd = {}

  if (typeof alert === 'string') {
    toAdd = { message: alert, duration: 3000 }
  } else {
    toAdd = alert
  }

  toAdd.id = performance.now()

  setState({ alerts: [...state.alerts, toAdd] })
}

const removeAlert = ({ id }) => {
  removing.push(id)
  if (!processingRemovals) processRemovals()
}

const processRemovals = () => {
  processingRemovals = true

  let alerts = [...state.alerts]

  while (removing.length > 0) {
    const id = removing.pop()
    const index = alerts.findIndex((alert) => alert.id === id)

    alerts.splice(index, 1)

    setState({ alerts })
  }

  processingRemovals = false
}

const useStore = () => {
  return {
    state,
    addAlert,
    removeAlert,
  }
}

export default useStore
