<template>
  <Shaker v-slot="{ shake }">
    <Form
      method="patch"
      :action="organization_path(organization.id)"
      @submit="(e) => handleSubmit({ ...e, onError: shake })"
    >
      <div class="space-y-8">
        <h1 class="text-xl font-light capitalize">{{ organizationLabel }}</h1>

        <div>
          <label>{{ organizationFieldLabel }}</label>
          <TextInput name="organization[name]" :value="organization.name" />
          <FieldError :error="errors.organization?.name" />
        </div>

        <div class="grid grid-cols-2 gap-4">
          <div>
            <label>Denomination</label>
            <SelectInput
              v-model="state.subscription.organization.denomination"
              name="organization[denomination]"
              :options="denominations"
              includeBlank
            />
            <FieldError :error="errors.organization?.denomination" />
          </div>

          <div>
            <label>Church Size</label>
            <SelectInput
              v-model="state.subscription.organization.size"
              name="organization[size]"
              :options="sizeOptions"
              includeBlank
            />
            <FieldError :error="errors.organization?.size" />
          </div>
        </div>

        <div class="space-y-2">
          <h2>Address</h2>

          <div class="grid grid-cols-1 md:grid-cols-2 gap-4">
            <div class="md:col-span-2">
              <label>Street Address</label>
              <TextInput name="organization[address_street]" :value="organization.address_street" />
              <FieldError :error="errors.organization?.address_street" />
            </div>

            <div>
              <label>City</label>
              <TextInput name="organization[address_city]" :value="organization.address_city" />
              <FieldError :error="errors.organization?.address_city" />
            </div>

            <div>
              <label>State</label>
              <SelectInput
                name="organization[address_state]"
                :options="stateCodeOptions"
                :value="organization.address_state"
                includeBlank
              />
              <FieldError :error="errors.organization?.address_state" />
            </div>

            <div>
              <label>Postal Code</label>
              <TextInput
                name="organization[address_postal_code]"
                :value="organization.address_postal_code"
              />
              <FieldError :error="errors.organization?.address_postal_code" />
            </div>
          </div>
        </div>

        <div>
          <label>Phone Number</label>
          <PhoneNumberInput name="organization[phone_number]" v-model="organization.phone_number" />
          <FieldError :error="errors.organization?.phone_number" />
        </div>

        <div>
          <Button type="submit" variant="primary" :disabled="!can.editOrganization || submitting">
            Update
          </Button>
        </div>
      </div>
    </Form>
  </Shaker>
</template>

<script setup>
import { ref, computed } from 'vue'
import Shaker from '@/vue_components/Shaker.vue'
import Form from '@/vue_components/Form.vue'
import TextInput from '@/vue_components/TextInput.vue'
import PhoneNumberInput from '@/vue_components/PhoneNumberInput.vue'
import SelectInput from '@/vue_components/SelectInput.vue'
import FieldError from '@/vue_components/FieldError.vue'
import Box from '@/vue_components/Box.vue'
import Button from '@/vue_components/Button.vue'
import { organization_path } from '@/routes.js'
import { submit } from '@/forms.js'
import { alert } from '@/alerts.js'
import { denominations, sizeOptions } from '@/church_information.js'
import { stateCodes } from '@/country_states.js'
import useStore from '../store.js'

const { state, loadSubscription, can } = useStore()

const subscription = computed(() => state.subscription)
const organization = computed(() => subscription.value.organization)
const stateCodeOptions = computed(() => stateCodes)
const organizationLabel = computed(() =>
  subscription.value.category === 'family' ? `${subscription.value.name} Church` : 'Organization',
)
const organizationFieldLabel = computed(() =>
  subscription.value.category === 'family' ? 'Church' : 'Organization',
)

const submitting = ref(false)
const errors = ref({})

const handleSubmit = async ({ form, onError }) => {
  submitting.value = true

  const response = await submit({ form })

  if (response.errors) {
    if (onError) onError()
    errors.value = response.errors
  } else {
    alert('Organization updated')
    errors.value = {}
    loadSubscription()
  }

  submitting.value = false
}
</script>
