<template>
  <div v-if="orders.length > 0" class="space-y-4">
    <Table :headers="orderData.headers" :rows="orderData.rows" />
    <PaginationControls :pagination="pagination" :load-page="loadPage" />
  </div>

  <div v-else>
    <Box>Nothing here yet!</Box>
  </div>
</template>

<script setup>
import { ref, computed, onMounted } from 'vue'
import Table from '@/vue_components/Table.vue'
import Button from '@/vue_components/Button.vue'
import PaginationControls from '@/vue_components/PaginationControls.vue'
import Box from '@/vue_components/Box.vue'
import { requestJSON } from '@/requests.js'
import { subscription_orders_path } from '@/routes.js'
import { currency } from '@/currency.js'
import { fullDate } from '@/formatting.js'

const props = defineProps({
  subscription: Object,
})

const orders = ref([])
const pagination = ref({})

const loadPage = async (page) => {
  const { body } = await requestJSON(subscription_orders_path(props.subscription.id, { page }))
  orders.value = body.orders
  pagination.value = body.pagination
}

const orderData = computed(() => {
  return {
    headers: [
      { display: 'Description', field: 'description' },
      { display: 'Placed by', field: 'placed_by' },
      { display: 'Processed On', field: 'processed_on' },
      { display: 'Amount', field: 'amount', columnClasses: ['text-right'] },
    ],
    rows: orders.value.map((order) => ({
      key: order.id,
      description: order.description,
      placed_by: order.user?.full_name || 'System',
      processed_on: fullDate(order.created_at),
      amount: currency(order.total_paid),
    })),
  }
})

onMounted(() => {
  loadPage(1)
})
</script>
