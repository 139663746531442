<template>
  <div v-if="stringError" class="text-red-500">
    {{ stringError }}
  </div>
</template>

<script setup>
import { computed, watch } from 'vue'

const props = defineProps({
  error: [String, Array],
})

const stringError = computed(() => (Array.isArray(props.error) ? props.error[0] : props.error))
</script>
