export const interpolate = (onTick, duration) => {
  return new Promise((resolve) => {
    let start

    function tick(timestamp) {
      if (typeof start === 'undefined') {
        start = timestamp
      }

      const elapsed = timestamp - start
      const t = Math.min(1.0, elapsed / duration)

      onTick(t, elapsed, duration)

      if (elapsed < duration) {
        requestAnimationFrame(tick)
      } else {
        resolve()
      }
    }

    requestAnimationFrame(tick)
  })
}
