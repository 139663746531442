<template>
  <div>
    <div class="space-y-4">
      <h1 class="text-xl font-light">Mentors</h1>

      <div class="">
        <Button
          variant="primary"
          class="relative"
          @click="addingMentor = true"
          :disabled="subscription.locked"
        >
          <span>Add Mentor</span>
        </Button>

        <span v-if="showFirstMentorAlert" class="inline-flex animate-move-back-and-forth">
          <p class="ml-2 text-gray-500">⬅️ Now is a great time to add your first mentor!</p>
        </span>
      </div>

      <Box v-if="mentors.length === 0">
        You haven't added any mentors yet. Why not go ahead and add the first one?
      </Box>

      <Table v-else :headers="mentorData.headers" :rows="mentorData.rows">
        <template #name-cell="{ row }">
          <Button v-if="!subscription.locked" variant="link" @click="editingMentor = row.mentor">
            {{ row.name }}
          </Button>
          <span v-else>{{ row.name }}</span>
        </template>
      </Table>
    </div>

    <AddMentorModal
      v-if="addingMentor"
      @close="addingMentor = false"
      @backdrop-click="addingMentor = false"
    />

    <EditMentorModal
      v-if="editingMentor"
      :mentor="{ ...editingMentor }"
      @close="editingMentor = null"
      @backdrop-click="editingMentor = null"
    />
  </div>
</template>

<script setup>
import { ref, computed, watch } from 'vue'
import Box from '@/vue_components/Box.vue'
import SvgIcon from '@/vue_components/SvgIcon.vue'
import Button from '@/vue_components/Button.vue'
import Table from '@/vue_components/Table.vue'
import AddMentorModal from './AddMentorModal.vue'
import EditMentorModal from './EditMentorModal.vue'
import useStore from '../store.js'
import { phoneNumber } from '@/formatting.js'

const { state, showFirstMentorAlert } = useStore()
const subscription = computed(() => state.subscription)
const mentors = computed(() => state.subscription.mentors)

const addingMentor = ref(false)
const editingMentor = ref(null)

const mentorData = computed(() => ({
  headers: [
    { display: 'Name', field: 'name' },
    { display: 'Email', field: 'email' },
    { display: 'Cell Phone', field: 'cell_phone' },
  ],
  rows: mentors.value.map((mentor) => ({
    mentor,
    name: mentor.full_name,
    email: mentor.email,
    cell_phone: phoneNumber(mentor.cell_phone || ''),
  })),
}))
</script>
