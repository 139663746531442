<template>
  <div class="fixed w-full h-full top-0 left-0">
    <transition appear @enter="animateInOverlay">
      <div class="absolute w-full h-full bg-gray-900 z-0" @click="$emit('backdrop-click')"></div>
    </transition>

    <transition appear @enter="animateInContent">
      <div class="absolute top-1/2 left-1/2 transform origin-top-left z-10 pointer-events-none">
        <div
          class="
            relative
            transform
            -translate-x-1/2 -translate-y-1/2
            bg-white
            rounded-md
            shadow-lg
            md:m-0
            pointer-events-auto
          "
          :class="classes"
          :style="styles"
        >
          <slot></slot>
        </div>
      </div>
    </transition>
  </div>
</template>

<script setup>
import { ref, computed } from 'vue'
import { interpolate } from '@/tweens.js'

const animationDuration = 150

const props = defineProps({
  contentSize: {
    type: String,
    default: 'full',
  },
  flush: {
    type: Boolean,
    default: false,
  },
})

const styles = computed(() => ({
  width: props.contentSize == 'full' ? '600px' : null,
}))

const classes = computed(() => ({
  'w-full': props.contentSize == 'full',
  'w-auto': props.contentSize == 'content',
  'p-0': props.flush,
  'p-4': !props.flush,
}))

const animateInOverlay = async (el, done) => {
  await interpolate((t) => {
    el.style.opacity = t * 0.7
  }, animationDuration)

  done()
}

const animateInContent = async (el, done) => {
  await interpolate((t) => {
    const scale = 0.8 + 0.2 * t * t * t * (3.0 - 2.0 * t)
    el.style.transform = `scale(${scale})`
    el.style.opacity = t
  }, animationDuration)

  done()
}
</script>
