<template>
  <form :method="useMethod" :action="action" @submit.prevent="handleSubmit">
    <input v-if="methodOverride" type="hidden" name="_method" :value="methodOverride" />
    <slot></slot>
  </form>
</template>

<script setup>
import { computed } from 'vue'
import { getCSRFToken } from '@/forms.js'

const props = defineProps({
  method: String,
  action: String,
  model: String,
})

const emit = defineEmits(['submit'])

const methodOverride = ['get', 'post'].includes(props.method) ? null : props.method

const useMethod = computed(() => (props.method == 'get' ? 'get' : 'post'))

const handleSubmit = (e) => {
  emit('submit', {
    form: e.target,
    formData: new FormData(e.target),
    event: e,
  })
}
</script>
