<template>
  <Modal>
    <div class="relative">
      <div v-if="currentState !== 'success'">
        <button class="absolute top-0 right-0" @click="emit('cancel')">
          <SvgIcon icon="close-outline" />
        </button>

        <div class="space-y-4">
          <h1 class="text-xl font-bold">Add additional passes</h1>

          <ProgressBreadcrumbs :steps="STEPS" :current-step="currentStep" />

          <ChoosePlan v-if="currentState === 'choosePlan'" />
          <SetAmount v-else-if="currentState === 'setAmount'" />
          <ChoosePaymentMethod v-else-if="currentState === 'choosePaymentMethod'" />
          <Finish v-else-if="currentState === 'finish'" />
        </div>
      </div>

      <div v-else>
        <Success />
      </div>
    </div>
  </Modal>
</template>

<script setup>
import { reactive, computed, ref, provide } from 'vue'
import { useStateMachine } from '@/state_machine.js'
import Modal from '@/vue_components/Modal.vue'
import ProgressBreadcrumbs from '@/vue_components/ProgressBreadcrumbs.vue'
import SvgIcon from '@/vue_components/SvgIcon.vue'
import ChoosePlan from './ChoosePlan.vue'
import SetAmount from './SetAmount.vue'
import ChoosePaymentMethod from './ChoosePaymentMethod.vue'
import Finish from './Finish.vue'
import Success from './Success.vue'
import useStore from '../../store.js'

const emit = defineEmits(['cancel'])

const STEPS = ['Choose a plan', 'Choose an amount', 'Payment', 'Finish']

const formData = reactive({
  membershipPassType: null,
  number: null,
  paymentMethod: null,
})

const { currentState, action } = useStateMachine({
  states: {
    choosePlan: {
      actions: {
        next: ({ goto, params }) => {
          formData.membershipPassType = params.membershipPassType
          goto('setAmount')
        },
      },
    },
    setAmount: {
      actions: {
        next: ({ goto, params }) => {
          formData.number = params.number
          goto('choosePaymentMethod')
        },
        back: ({ goto }) => {
          formData.membershipPassType = null
          goto('choosePlan')
        },
      },
    },
    choosePaymentMethod: {
      actions: {
        next: ({ goto, params }) => {
          formData.paymentMethod = params.paymentMethod
          goto('finish')
        },
        back: ({ goto }) => {
          formData.number = null
          goto('setAmount')
        },
      },
    },
    finish: {
      actions: {
        next: ({ goto }) => {
          goto('success')
        },
        back: ({ goto }) => {
          formData.paymentMethod = null
          goto('choosePaymentMethod')
        },
      },
    },
    success: {},
  },
  initialState: 'choosePlan',
})

const currentStep = computed(() => {
  const stateToStep = {
    choosePlan: 0,
    setAmount: 1,
    choosePaymentMethod: 2,
    finish: 3,
  }

  return stateToStep[currentState.value]
})

provide('formData', formData)
provide('action', action)
provide('cancel', () => emit('cancel'))
</script>
