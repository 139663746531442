<template>
  <button ref="self" class="relative inline-block" @click="showActions">
    <div class="flex items-center space-x-2">
      <slot></slot>
      <SvgIcon
        icon="cheveron-down"
        class="text-gray-500 transition-transform"
        :class="showingActions ? 'transform rotate-180' : 'transform rotate-0'"
      />
    </div>

    <div
      v-if="showingActions"
      class="
        absolute
        top-100
        left-1/2
        border border-gray-300
        bg-white
        rounded-md
        shadow-lg
        transform
        -translate-x-1/2
        translate-y-1
        overflow-hidden
        z-10
      "
    >
      <slot name="actions" :perform="perform" :buttonClasses="buttonClasses"></slot>
    </div>
  </button>
</template>

<script setup>
import { ref, computed, onMounted, onUnmounted } from 'vue'
import SvgIcon from '@/vue_components/SvgIcon.vue'

const self = ref()
const showingActions = ref(false)
const hideTimer = ref(null)
const baseButtonClasses = ['block', 'w-full', 'text-center', 'py-2', 'px-6', 'hover:bg-gray-100']

const buttonClasses = {
  default: baseButtonClasses,
  destructive: [...baseButtonClasses, 'text-red-500', 'font-bold'],
}

const onClickOut = (e) => {
  if (!showingActions.value) return

  let node = e.target
  while (node) {
    if (node === self.value) {
      e.preventDefault()
      return
    }

    node = node.parentNode
  }

  showingActions.value = false
}

onMounted(() => {
  window.addEventListener('click', onClickOut)
})

onUnmounted(() => {
  window.removeEventListener('click', onClickOut)
})

const showActions = () => {
  showingActions.value = true
}

const hideActions = () => {
  hideTimer.value = null
  showingActions.value = false
}

const toggleActions = () => {
  showingActions.value = !showingActions.value
}

const perform = (action) => {
  if (action) {
    action()
  }

  hideActions()
}
</script>
