<template>
  <div class="space-y-4">
    <h2 class="text-xl font-light uppercase text-center space-y-2">
      <SvgIcon icon="checkmark-outline" />
      <div>Welcome to INFLUNSR!</div>
    </h2>

    <div class="space-y-4">
      <p>
        Your subscription has been created,
        <strong>but it's not quite ready.</strong>

        <span v-if="paymentMethod">
          It looks like you already have a card on file, so all you need to do now is review the
          subscription fee and process your payment.
        </span>

        <span v-else>
          First things first, you need to set up a payment method to use for your subscription.
        </span>

        <span v-if="subscription.free_trial_active">
          <strong>
            We will not charge your card until your free trial ends on {{ freeTrialEndDate }}.
          </strong>
        </span>
      </p>
    </div>

    <div class="flex justify-center">
      <Button variant="primary" @click="next">
        <span class="inline-block mr-1">{{ actionLabel }}</span>
        <SvgIcon icon="cheveron-right" />
      </Button>
    </div>
  </div>
</template>

<script setup>
import { computed, inject } from 'vue'
import Button from '@/vue_components/Button.vue'
import SvgIcon from '@/vue_components/SvgIcon.vue'
import useStore from '../../store.js'
import { fullDate } from '@/formatting.js'

const emit = defineEmits(['next'])

const { state } = useStore()
const subscription = computed(() => state.subscription)
const freeTrialEndDate = computed(() =>
  state.subscription.free_trial_active ? fullDate(state.subscription.free_trial_ends_at) : '',
)

const paymentMethod = inject('paymentMethod')
const actionLabel = computed(() => {
  return paymentMethod.value ? 'Review Payment' : 'Add Payment Method'
})

const next = () => {
  emit('next')
}
</script>
