<template>
  <div class="text-center space-y-4 py-4">
    <SvgIcon icon="checkmark-outline" size="h-16" class="text-green-500" />

    <p class="text-gray-500 px-4 text-center">
      Your
      <strong class="text-gray-900">{{ formData.number }} passes</strong>
      were added successfully!
    </p>

    <div>
      <Button variant="primary" @click="cancel">Close</Button>
    </div>
  </div>
</template>

<script setup>
import { inject } from 'vue'
import Button from '@/vue_components/Button.vue'
import SvgIcon from '@/vue_components/SvgIcon.vue'

const formData = inject('formData')
const cancel = inject('cancel')
</script>
