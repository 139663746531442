<template>
  <Modal>
    <div class="space-y-4">
      <div>
        <p class="text-xl font-bold">{{ membership.full_name }}</p>
        <p>{{ membership.email }}</p>
        <p>{{ phoneNumber(membership.cell_phone) }}</p>
        <p>{{ membership.grade_level }} @ {{ membership.school }}</p>
      </div>

      <div>
        <p class="uppercase text-gray-500 text-sm">Parent(s)</p>
        <div class="space-y-2">
          <Box v-for="parent of membership.parents" :key="parent.name">
            <p>
              {{ parent.name }}
              <span class="text-gray-500">({{ parent.relationship }})</span>
            </p>
            <p class="text-sm">{{ parent.email }} &middot; {{ phoneNumber(parent.cell_phone) }}</p>
          </Box>
        </div>
      </div>

      <div v-if="mentor">
        <p class="uppercase text-gray-500 text-sm">Mentor</p>
        <Box>
          <p>{{ mentor.full_name }}</p>
          <p class="text-sm">
            {{ mentor.email }} &middot;
            {{ phoneNumber(mentor.cell_phone || '') }}
          </p>
        </Box>
      </div>
    </div>
  </Modal>
</template>

<script setup>
import { computed } from 'vue'
import Modal from '@/vue_components/Modal.vue'
import Box from '@/vue_components/Box.vue'
import { alert } from '@/alerts.js'
import useStore from '../store.js'
import { phoneNumber } from '@/formatting.js'

const props = defineProps({
  membership: Object,
})

const emit = defineEmits(['close'])

const mentor = computed(() => props.membership.mentor)
</script>
