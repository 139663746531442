import { computed } from 'vue'
import { useState } from '@/states.js'
import { requestJSON } from '@/requests.js'
import {
  subscription_path,
  new_subscription_payment_method_path,
  subscription_payment_methods_path,
  subscription_payment_method_path,
  subscription_orders_path,
  membership_pass_types_path,
  membership_path,
  membership_invitation_path,
  subscription_organization_path,
} from '@/routes.js'

const { state, setState } = useState({
  loading: false,
  subscription: null,
  membershipPassTypes: [],
  paymentMethods: [],
  paymentMethodsLoaded: false,
  currentUser: null,
})

const currentUserIsOwner = computed(() => {
  let currentUser = state.currentUser || { id: null }

  if (!currentUser.id == null) {
    return false
  }

  if (!state.subscription) {
    return false
  }

  return state.subscription.owners.some((owner) => owner.user?.id == currentUser.id)
})

const can = computed(() => {
  if (!state.subscription) {
    return {}
  }

  return {
    setupSubscription: currentUserIsOwner.value,
    inviteMembers:
      !state.subscription.locked &&
      !state.subscription.free_trial_active &&
      !state.subscription.failed_renewal,
    addPasses:
      !state.subscription.locked &&
      !state.subscription.free_trial_active &&
      !state.subscription.marked_for_cancellation &&
      !state.subscription.failed_renewal,
    editMembers: !state.subscription.locked,
    editOrganization: !state.subscription.locked,
    addPaymentMethods: !state.subscription.marked_for_cancellation,
    editPaymentMethods: !state.subscription.locked,
    cancelSubscription: !state.subscription.marked_for_cancellation && !state.subscription.locked,
    uncancelSubscription: state.subscription.marked_for_cancellation && !state.subscription.locked,
  }
})

const loadSubscription = async () => {
  if (!state.subscription) return

  state.loading = true

  const { body } = await requestJSON(subscription_path(state.subscription.id))

  if (body) {
    state.subscription = body
  }

  state.loading = false
}

const deleteMember = async (member) => {
  setState({ loading: true })

  const { response } = await requestJSON(membership_path(member.id), {
    method: 'DELETE',
  })

  if (response.ok) {
    loadSubscription()
  } else {
    setState({ loading: false })
  }
}

const deleteInvitation = async (invitation) => {
  setState({ loading: true })

  const { response } = await requestJSON(membership_invitation_path(invitation.id), {
    method: 'DELETE',
  })

  if (response.ok) {
    loadSubscription()
  } else {
    setState({ loading: false })
  }
}

const loadPaymentMethods = async () => {
  if (!state.subscription) return

  const { body } = await requestJSON(subscription_payment_methods_path(state.subscription.id))

  setState({ paymentMethods: body, paymentMethodsLoaded: true })
}

const buildPaymentMethod = async () => {
  if (!state.subscription) return

  const { body } = await requestJSON(new_subscription_payment_method_path(state.subscription.id))

  return body.setup_intent
}

const createPaymentMethod = async (stripePaymentMethodId, { isDefault = false } = {}) => {
  if (!state.subscription) return

  return await requestJSON(subscription_payment_methods_path(state.subscription.id), {
    method: 'POST',
    body: {
      payment_method: { stripe_payment_method_id: stripePaymentMethodId, is_default: isDefault },
    },
  })
}

const updatePaymentMethod = async (id, params = {}) => {
  if (!state.subscription) return

  setState({ loading: true })

  const { response } = await requestJSON(
    subscription_payment_method_path(state.subscription.id, id),
    {
      method: 'PATCH',
      body: params,
    },
  )

  setState({ loading: false })

  return response
}

const deletePaymentMethod = async (id) => {
  if (!state.subscription) return

  setState({ loading: true })

  const { response } = await requestJSON(
    subscription_payment_method_path(state.subscription.id, id),
    {
      method: 'DELETE',
    },
  )

  setState({ loading: false })

  return response
}

const loadMembershipPassTypes = async () => {
  const { body } = await requestJSON(membership_pass_types_path())

  setState({ membershipPassTypes: body })
}

const updateMembership = async (id, params = {}) => {
  if (!state.subscription) return

  setState({ loading: true })

  const { response, body } = await requestJSON(membership_path(id), {
    method: 'PATCH',
    body: params,
  })

  setState({ loading: false })

  return { response, body }
}

const showFirstStudentAlert = computed(
  () =>
    state.subscription.active &&
    !state.subscription.locked &&
    state.subscription.subscribers.length === 0,
)
const showFirstMentorAlert = computed(
  () =>
    state.subscription.active &&
    !state.subscription.locked &&
    state.subscription.mentors.length === 0,
)

const useStore = () => {
  return {
    state,
    setState,
    loadSubscription,
    deleteMember,
    deleteInvitation,
    buildPaymentMethod,
    createPaymentMethod,
    updatePaymentMethod,
    deletePaymentMethod,
    loadPaymentMethods,
    loadMembershipPassTypes,
    updateMembership,
    can,
    showFirstStudentAlert,
    showFirstMentorAlert,
  }
}

export default useStore
