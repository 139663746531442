<template>
  <div>
    <div class="space-y-4">
      <Box
        :class="
          subscription.active && !subscription.marked_for_cancellation && !subscription.locked
            ? 'border-green-500 bg-green-100'
            : ''
        "
      >
        <p class="text-gray-500">Subscription</p>
        <p class="font-bold text-xl capitalize">
          {{ subscription.name }} Subscription &mdash; {{ currency(subscription.cost) }} / year
        </p>
        <p class="font-light text-gray-500">
          Created {{ subscriptionCreatedAt }} &middot; {{ renewalText }}
        </p>
      </Box>

      <Box v-if="subscription.free_trial_active" class="border-pink-500 bg-pink-100 space-y-2">
        <div>
          <p class="text-gray-500">Free Trial</p>
          <p class="font-bold text-xl">Active until {{ freeTrialEndsAt }}</p>
        </div>
        <p>
          You and any mentors you add will receive weekly free content until your free trial
          completes. Once your free trial completes, we will automatically process payment for your
          subscription and you will be able to start inviting students. You can also activate your
          subscription immediately at any time. We'll charge your card and enable inviting students.
        </p>
        <p>
          <Button class="bg-pink-500 text-white" @click="endingFreeTrial = true">
            Activate Subscription
          </Button>
        </p>
      </Box>

      <div class="grid grid-cols-1 lg:grid-cols-2 gap-4">
        <Box class="space-y-4">
          <p class="font-bold text-xl">Student Annual Passes</p>

          <div class="flex">
            <Button
              variant="bordered"
              class="w-full md:w-auto"
              @click="showAdditionalPassesModal = true"
              :disabled="!can.addPasses"
            >
              <SvgIcon icon="user-add" class="mr-2"></SvgIcon>
              Add additional passes
            </Button>
          </div>

          <table class="w-full">
            <tbody>
              <tr v-for="membershipPassType of membershipPassTypes" :key="membershipPassType.id">
                <td class="py-4 border-b border-gray-200">
                  <span class="text-lg">{{ membershipPassType.name }}</span>
                  <span>&nbsp; &times; {{ passCountsByType[membershipPassType.id] || 0 }}</span>
                </td>
                <td class="py-4 border-b border-gray-200 text-right">
                  {{ currency(membershipPassType.price) }} / pass
                </td>
                <td class="py-4 border-b border-gray-200 text-right">
                  {{
                    currency(
                      membershipPassType.price * (passCountsByType[membershipPassType.id] || 0),
                    )
                  }}
                </td>
              </tr>

              <tr>
                <td colspan="4" class="py-4 text-right">
                  <span class="inline-flex items-center space-x-1">
                    <span class="font-bold text-xl">
                      {{ currency(totalPassCost) }}
                    </span>
                    <span class="text-gray-500 text-sm">/ year</span>
                  </span>
                </td>
              </tr>
            </tbody>
          </table>
        </Box>
      </div>

      <div class="space-y-2">
        <div>
          <h2>Owners</h2>
          <p class="text-gray-500 text-sm font-light">
            Owners are able to manage the organization and receive communication from the system
          </p>
        </div>

        <Box v-for="owner of owners" :key="owner.id">
          <div v-if="owner.user">
            <p>{{ owner.user.full_name }} &middot; {{ owner.job_title }}</p>
            <p class="text-sm text-gray-500">{{ owner.user.email }}</p>
          </div>
        </Box>
      </div>
    </div>

    <div class="mt-32 space-y-4">
      <Box v-if="can.cancelSubscription">
        <div class="flex items-center">
          <div class="flex-grow">
            <h2 class="text-red-500">{{ cancellationLabel }}</h2>
            <p class="pr-8 max-w-[800px]">
              {{ cancellationMessage }}
            </p>
          </div>
          <div class="text-right">
            <Button
              variant="custom"
              class="bg-transparent text-red-500 border border-red-500"
              @click="cancellingSubscription = subscription"
            >
              {{ cancellationCallToAction }}
            </Button>
          </div>
        </div>
      </Box>

      <Box v-if="can.uncancelSubscription">
        <div class="flex items-center">
          <div class="flex-grow">
            <h2 class="text-green-500">Turn On Automatic Renewal</h2>
            <p class="pr-8 max-w-[800px]">
              Turning on automatic renewal will enable your subscription to automatically be renewed
              once it expires.
            </p>
          </div>
          <div class="text-right">
            <Button
              variant="custom"
              class="bg-transparent text-green-500 border border-green-500"
              @click="uncancellingSubscription = subscription"
            >
              Turn On Automatic Renewal
            </Button>
          </div>
        </div>
      </Box>
    </div>

    <ConfirmationModal
      v-if="cancellingSubscription"
      @confirm="handleCancelSubscription(cancellingSubscription)"
      @deny="cancellingSubscription = null"
    >
      <div class="text-center p-4 space-y-4">
        <SvgIcon icon="exclamation-outline" class="text-yellow-400 h-20" />
        <p v-if="subscription.free_trial_active">
          Are you sure you want to cancel your subscription?
        </p>
        <p v-else>Are you sure you want to turn off automatic renewal?</p>
      </div>
    </ConfirmationModal>

    <ConfirmationModal
      v-if="uncancellingSubscription"
      @confirm="handleUncancelSubscription(uncancellingSubscription)"
      @deny="uncancellingSubscription = null"
    >
      <div class="text-center p-4 space-y-4">
        <SvgIcon icon="exclamation-outline" class="text-yellow-400 h-20" />
        <p>Are you sure you want to turn on automatic renewal?</p>
      </div>
    </ConfirmationModal>

    <ConfirmationModal
      v-if="endingFreeTrial"
      @confirm="handleEndingFreeTrial"
      @deny="endingFreeTrial = false"
    >
      <div class="text-center p-4 space-y-4">
        <SvgIcon icon="exclamation-outline" class="text-yellow-400 h-20" />
        <p>
          Are you sure you want to end your free trial early? We will immediately process payment
          for your annual subscription.
        </p>
      </div>
    </ConfirmationModal>

    <AdditionalPassesModal
      v-if="showAdditionalPassesModal"
      @cancel="showAdditionalPassesModal = false"
    />
  </div>
</template>

<script setup>
import { ref, computed, watch } from 'vue'
import { currency } from '@/currency.js'
import Box from '@/vue_components/Box.vue'
import SvgIcon from '@/vue_components/SvgIcon.vue'
import Button from '@/vue_components/Button.vue'
import Shaker from '@/vue_components/Shaker.vue'
import ConfirmationModal from '@/vue_components/ConfirmationModal.vue'
import AdditionalPassesModal from './AdditionalPassesModal/Index.vue'
import {
  cancel_subscription_path,
  uncancel_subscription_path,
  end_free_trial_subscription_path,
} from '@/routes.js'
import { request } from '@/requests.js'
import { alert } from '@/alerts.js'
import { fullDate } from '@/formatting.js'
import useStore from '../store.js'

const { state, loadSubscription, can } = useStore()

const showAdditionalPassesModal = ref(false)
const subscription = computed(() => state.subscription)
const membershipPassTypes = computed(() => state.membershipPassTypes)
const passes = computed(() => state.subscription.membership_passes)
const owners = computed(() => subscription.value.owners)

const cancellationLabel = computed(() => {
  if (subscription.value.free_trial_active) {
    return 'Cancel Subscription'
  } else {
    return 'Turn Off Automatic Renewal'
  }
})
const cancellationMessage = computed(() => {
  if (subscription.value.free_trial_active) {
    return 'Cancelling your subscription during the free trial will end the free trial. You will no longer receive content and your subscription will be locked.'
  } else {
    return 'Turning off automatic renewal will let you finish up the rest of your subscription duration. Once your subscription finishes, it will not automatically renew and your subscribers will no longer receive content.'
  }
})
const cancellationCallToAction = computed(() => {
  if (subscription.value.free_trial_active) {
    return 'Cancel Subscription'
  } else {
    return 'Turn Off Automatic Renewal'
  }
})

const cancellingSubscription = ref(null)
const handleCancelSubscription = async (subscription) => {
  state.loading = true

  const { response } = await request(cancel_subscription_path(subscription.id), {
    method: 'patch',
  })

  if (response.ok) {
    loadSubscription()
  } else {
    alert('There was a problem when cancelling your subscription.')
    state.loading = false
  }

  cancellingSubscription.value = null
}

const uncancellingSubscription = ref(null)
const handleUncancelSubscription = async (subscription) => {
  state.loading = true

  const { response } = await request(uncancel_subscription_path(subscription.id), {
    method: 'patch',
  })

  if (response.ok) {
    loadSubscription()
  } else {
    alert('There was a problem when turning automatic renewal back on for your subscription.')
    state.loading = false
  }

  uncancellingSubscription.value = null
}

const endingFreeTrial = ref(false)
const handleEndingFreeTrial = async () => {
  state.loading = true

  const { response } = await request(end_free_trial_subscription_path(subscription.value.id), {
    method: 'patch',
  })

  if (response.ok) {
    loadSubscription()
  } else {
    alert('There was a problem when ending your free trial.')
    state.loading = false
  }

  endingFreeTrial.value = false
}

const passCountsByType = computed(() =>
  passes.value.reduce((groupedByType, pass) => {
    groupedByType[pass.membership_pass_type_id] = groupedByType[pass.membership_pass_type_id] || 0
    groupedByType[pass.membership_pass_type_id] += pass.number
    return groupedByType
  }, {}),
)

const totalPassCost = computed(() =>
  membershipPassTypes.value.reduce(
    (sum, type) => sum + type.price * (passCountsByType.value[type.id] || 0),
    0,
  ),
)

const subscriptionCreatedAt = computed(() => fullDate(subscription.value.created_at))
const subscriptionRenewsAt = computed(() => fullDate(subscription.value.next_renewal_at))
const subscriptionCancelledAt = computed(() => fullDate(subscription.value.cancelled_at))
const freeTrialEndsAt = computed(() => fullDate(subscription.value.free_trial_ends_at))

const renewalText = computed(() => {
  if (subscription.value.marked_for_cancellation) {
    if (subscription.value.active && subscription.value.past_renewal) {
      return `Cancelled ${subscriptionCancelledAt.value}`
    } else {
      return `Cancelled ${subscriptionCancelledAt.value} - Will end on ${subscriptionRenewsAt.value}`
    }
  } else if (subscription.value.free_trial_active) {
    return `Free trial ends ${freeTrialEndsAt.value}`
  } else if (subscription.value.past_renewal) {
    return `Ended ${subscriptionRenewsAt.value}`
  } else {
    return `Renews ${subscriptionRenewsAt.value}`
  }
})
</script>
