<template>
  <Modal flush>
    <MentorChooser
      :mentors="mentors"
      :selected-mentor-id="forStudent.mentor?.id"
      highlight-selected
      @choose-mentor="chooseMentor"
    />
  </Modal>
</template>

<script setup>
import { ref, computed } from 'vue'
import Modal from '@/vue_components/Modal.vue'
import TextInput from '@/vue_components/TextInput.vue'
import Box from '@/vue_components/Box.vue'
import MentorChooser from './MentorChooser.vue'
import { alert } from '@/alerts.js'
import useStore from '../store.js'

const props = defineProps({
  forStudent: {
    type: Object,
  },
})

const emit = defineEmits(['close'])

const { state, setState, updateMembership, loadSubscription } = useStore()

const mentors = computed(() => state.subscription.mentors || [])

const chooseMentor = async (mentor) => {
  const { response, body } = await updateMembership(props.forStudent.id, {
    membership: {
      mentor_relationship_attributes: { mentor_id: mentor.id },
    },
  })

  if (!response.ok) {
    alert('Something went wrong')
  } else if (body.errors) {
    alert('Unable to update mentor')
  } else {
    let subscribers = [...state.subscription.subscribers]
    const index = subscribers.findIndex((subscriber) => subscriber.id === body.id)
    subscribers.splice(index, 1, body)

    setState({
      subscription: {
        ...state.subscription,
        subscribers,
      },
    })

    alert('Mentor updated')

    emit('close')
  }
}
</script>
