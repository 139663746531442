<template>
  <svg v-bind="svgAttributes" v-html="svgContent" :class="classes" />
</template>

<script>
import { ref, computed, watch, onMounted } from 'vue'

const cachedContent = {}

const cache = async (src, loadFunction) => {
  if (cachedContent[src]) return cachedContent[src]

  cachedContent[src] = loadFunction(src)

  return cachedContent[src]
}

export default {
  props: {
    src: {
      type: String,
    },
  },
  setup(props) {
    const loaded = ref(false)
    const svgAttributes = ref({})
    const svgContent = ref('')

    const load = async (src) => {
      const response = await fetch(src)
      const text = await response.text()
      return text
    }

    const fetchContent = async (src) => {
      const raw = await load(src)
      const container = document.createElement('div')
      container.innerHTML = raw
      const svg = container.querySelector('svg')

      const attributes = Array.from(svg.attributes).reduce((attributes, attr) => {
        attributes[attr.name] = attr.value
        return attributes
      }, {})

      const content = svg.innerHTML

      return { attributes, content }
    }

    const getContent = async (src) => {
      const { attributes, content } = await cache(src, fetchContent)

      svgAttributes.value = attributes
      svgContent.value = content
      loaded.value = true
    }

    const classes = computed(() => ({
      'h-4 w-4': !loaded.value,
    }))

    watch(
      () => props.src,
      async (src) => {
        getContent(src)
      },
    )

    onMounted(async () => {
      getContent(props.src)
    })

    return {
      svgContent,
      svgAttributes,
      classes,
    }
  },
}
</script>
