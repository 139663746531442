const VueFeatures = import.meta.globEager('./vue_features/**/*_feature/index.js')

document.addEventListener('DOMContentLoaded', () => {
  const vueFeatureElements = document.querySelectorAll('[data-vue-feature]')

  for (const featureElement of Array.from(vueFeatureElements)) {
    const featureString = featureElement.getAttribute('data-vue-feature')
    const propsString = featureElement.getAttribute('data-vue-props')
    const stateString = featureElement.getAttribute('data-vue-state')
    const featureRenderer = VueFeatures[`./vue_features/${featureString}_feature/index.js`].default

    if (featureRenderer) {
      const props = propsString ? JSON.parse(propsString) : {}
      const state = stateString ? JSON.parse(stateString) : {}
      featureRenderer({ el: featureElement, props, state })
    }
  }
})

export default VueFeatures
