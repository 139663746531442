<template>
  <div class="space-y-4">
    <div class="space-y-1">
      <Box v-if="paymentMethods.length === 0">
        You don't have any payment methods set up yet!
        <router-link to="/billing">You can add one in the Billing section.</router-link>
      </Box>

      <div
        v-for="paymentMethod in paymentMethods"
        :key="paymentMethod.id"
        class="relative cursor-pointer"
        @click="selectedPaymentMethod = paymentMethod"
      >
        <PaymentMethodListItem :payment-method="paymentMethod" />

        <div
          v-if="selectedPaymentMethod && selectedPaymentMethod.id === paymentMethod.id"
          class="absolute top-1/2 right-0 -translate-x-1/2 -translate-y-1/2"
        >
          <SvgIcon icon="checkmark-outline" size="h-8" class="text-blue-500" />
        </div>
      </div>
    </div>

    <div class="grid grid-cols-2 gap-4">
      <Button variant="secondary" @click="action('back')">Back</Button>
      <Button variant="primary" @click="selectPaymentMethod" :disabled="!selectedPaymentMethod">
        Select Payment Method
      </Button>
    </div>
  </div>
</template>

<script setup>
import { ref, computed, inject } from 'vue'
import Box from '@/vue_components/Box.vue'
import Button from '@/vue_components/Button.vue'
import SvgIcon from '@/vue_components/SvgIcon.vue'
import PaymentMethodListItem from '../PaymentMethodListItem.vue'
import useStore from '../../store.js'

const action = inject('action')
const formData = inject('formData')
const cancel = inject('cancel')

const { state } = useStore()
const paymentMethods = computed(() => state.paymentMethods)
const selectedPaymentMethod = ref(null)

const selectPaymentMethod = () => {
  action('next', { paymentMethod: selectedPaymentMethod.value })
}
</script>
