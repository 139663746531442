<template>
  <div class="p-4 bg-green-500 rounded-md shadow-md" :style="fadeOutStyles">
    <slot></slot>
  </div>
</template>

<script setup>
import { ref, onMounted } from 'vue'
import { interpolate } from '@/tweens.js'

const props = defineProps({
  duration: {
    type: Number,
    default: 3000,
  },
})

const emit = defineEmits(['complete'])

const fadeOutStyles = ref({})

const fadeOut = async () => {
  await interpolate((t) => {
    const offset = `-${t * 10}px`

    fadeOutStyles.value = {
      transform: `translateY(${offset})`,
      opacity: 1 - t,
    }
  }, 1000)

  emit('complete')
}

onMounted(() => {
  setTimeout(fadeOut, props.duration)
})
</script>
