<template>
  <TextInput v-model="value" :mask="formatPhoneNumber" v-bind="$attrs" />
</template>

<script setup>
import { ref, computed } from 'vue'
import TextInput from './TextInput.vue'
import { phoneNumber } from '@/formatting.js'

const props = defineProps({
  modelValue: {
    type: String,
    default: '',
  },
  allowExtension: {
    type: Boolean,
    default: false,
  },
})

const emit = defineEmits(['update:modelValue'])

const formatPhoneNumber = (value) =>
  phoneNumber(value || '', {
    allowExtension: props.allowExtension,
  }).replace(/\D/g, '')

const value = computed({
  get: () => {
    return phoneNumber(props.modelValue || '', { allowExtension: props.allowExtension })
  },
  set: (value) => {
    emit('update:modelValue', value)
  },
})
</script>
