<template>
  <div class="fixed top-0 right-0 p-4 space-y-4">
    <Alert v-for="alert in alerts" :key="alert.id" :duration="alert.duration" @complete="onAlertComplete(alert)">
      {{ alert.message }}
    </Alert>
  </div>
</template>

<script setup>
import { computed } from 'vue'
import Alert from '@/vue_components/Alert.vue'
import siteAlertsStore from '../store.js'

const { state, removeAlert } = siteAlertsStore()

const alerts = computed(() => state.alerts)

const onAlertComplete = (alert) => {
  removeAlert(alert)
}
</script>
