<template>
  <div class="space-y-4 lg:space-y-8">
    <h2 class="text-xl font-light uppercase text-center space-y-2">
      <SvgIcon icon="user-solid-circle" />
      <div>Tell us about yourself</div>
    </h2>

    <div class="grid grid-cols-2 gap-4 lg:gap-8">
      <div>
        <label>First Name</label>
        <TextInput v-model="state.formData.owner.first_name" type="text" />
        <FieldError :error="errors.first_name" />
      </div>

      <div>
        <label>Last Name</label>
        <TextInput v-model="state.formData.owner.last_name" type="text" />
        <FieldError :error="errors.last_name" />
      </div>
    </div>

    <div>
      <label>Job Title</label>
      <div class="space-y-2">
        <SelectInput v-model="jobTitleSelection" :options="jobTitleOptions" />
        <div v-if="jobTitleSelection === 'Other'">
          <TextInput v-model="jobTitleOverride" />
        </div>
      </div>
      <FieldError :error="errors.job_title" />
    </div>

    <div>
      <label>Email</label>
      <TextInput v-model="state.formData.owner.email" type="email" />
      <FieldError :error="errors.email" />
    </div>

    <div>
      <label>Cell Phone</label>
      <PhoneNumberInput v-model="state.formData.owner.cell_phone" />
      <FieldError :error="errors.cell_phone" />
    </div>

    <div class="grid grid-cols-2 gap-4 pt-4">
      <Button variant="secondary" @click="back">
        <SvgIcon icon="cheveron-left" />
        <span class="inline-block ml-1">Back</span>
      </Button>

      <Button variant="primary" @click="next">
        <span class="inline-block mr-1">Next</span>
        <SvgIcon icon="cheveron-right" />
      </Button>
    </div>
  </div>
</template>

<script setup>
import { ref, computed, watch } from 'vue'
import TextInput from '@/vue_components/TextInput.vue'
import SelectInput from '@/vue_components/SelectInput.vue'
import FieldError from '@/vue_components/FieldError.vue'
import PhoneNumberInput from '@/vue_components/PhoneNumberInput.vue'
import SvgIcon from '@/vue_components/SvgIcon.vue'
import Button from '@/vue_components/Button.vue'
import { requestJSON } from '@/requests.js'
import useStore from '../store.js'

const emit = defineEmits(['next', 'back'])

const { state } = useStore()
const errors = ref({})

const jobTitleOptions = [
  '',
  'Youth Leader (Full Time)',
  'Youth Leader (Bi Vocational)',
  'Church Administrator',
  'Parent',
  'Other',
]
const jobTitleSelection = ref(state.formData.owner.job_title)
const jobTitleOverride = ref('')

watch([jobTitleSelection, jobTitleOverride], ([selected, override], from) => {
  state.formData.owner.job_title = selected === 'Other' ? override : selected
})

const back = () => {
  emit('back')
}

const next = async () => {
  errors.value = {}

  const { body } = await requestJSON('/join', { method: 'post', body: state.formData })

  if (body.errors && body.errors.owner && Object.keys(body.errors.owner).length > 0) {
    errors.value = body.errors.owner
  } else {
    emit('next')
  }
}
</script>
