<template>
  <Box class="space-y-4">
    <div class="grid grid-cols-2 gap-4">
      <div>
        <label>First Name</label>
        <TextInput type="text" v-model="formData.first_name" />
        <FieldError :error="errors.membership?.first_name" />
      </div>

      <div>
        <label>Last Name</label>
        <TextInput type="text" v-model="formData.last_name" />
        <FieldError :error="errors.membership?.last_name" />
      </div>
    </div>

    <div>
      <label>Email</label>
      <TextInput type="text" v-model="formData.email" />
      <FieldError :error="errors.membership?.email" />
    </div>

    <div>
      <label>Cell Phone</label>
      <PhoneNumberInput v-model="formData.cell_phone" />
      <FieldError :error="errors.membership?.cell_phone" />
    </div>

    <div class="grid grid-cols-2 gap-4">
      <div>
        <label>School</label>
        <TextInput type="text" v-model="formData.school" />
        <FieldError :error="errors.membership?.school" />
      </div>

      <div>
        <label>Grade Level</label>
        <SelectInput :options="GRADE_LEVELS" v-model="formData.grade_level" />
        <FieldError :error="errors.membership?.grade_level" />
      </div>
    </div>
  </Box>
</template>

<script setup>
import { inject, ref, computed } from 'vue'
import Box from '@/vue_components/Box.vue'
import Button from '@/vue_components/Button.vue'
import TextInput from '@/vue_components/TextInput.vue'
import PhoneNumberInput from '@/vue_components/PhoneNumberInput.vue'
import SelectInput from '@/vue_components/SelectInput.vue'
import FieldError from '@/vue_components/FieldError.vue'
import SvgIcon from '@/vue_components/SvgIcon.vue'
import { requestJSON } from '@/requests.js'

const GRADE_LEVELS = ['', 'Middle School', '9th', '10th', '11th', '12th', 'College']
const FIELDS = ['first_name', 'last_name', 'email', 'cell_phone', 'school', 'grade_level']

const membership = inject('membership')
const formData = inject('formData')
const errors = inject('errors')
</script>
