<template>
  <div class="flex items-center space-x-8 bg-white shadow-md p-4">
    <span class="text-lg font-extrabold">INFLUNSR</span>

    <template v-if="currentUser">
      <ActionButton v-if="currentUser.is_admin">
        <template v-slot:default>
          <span>Admin</span>
        </template>

        <template v-slot:actions="{ perform, buttonClasses }">
          <div class="w-max">
            <a :class="buttonClasses.default" :href="subscriptions_path()" @click="perform()">
              Subscriptions
            </a>

            <a :class="buttonClasses.default" :href="system_settings_path()" @click="perform()">
              System Settings
            </a>
          </div>
        </template>
      </ActionButton>

      <ActionButton v-if="subscriptions.length > 0">
        <template v-slot:default>
          <span>My Subscriptions</span>
        </template>

        <template v-slot:actions="{ perform, buttonClasses }">
          <div class="w-max">
            <a
              v-for="subscription of subscriptions"
              :key="subscription.id"
              :href="subscription_path(subscription.id)"
              :class="buttonClasses.default"
              class="capitalize"
              @click="perform()"
            >
              {{ subscription.name }} Subscription
            </a>
          </div>
        </template>
      </ActionButton>

      <span>
        <ActionButton v-if="currentUser">
          <template v-slot:default>
            <span>{{ currentUser.first_name }} {{ currentUser.last_name }}</span>
          </template>

          <template v-slot:actions="{ perform, buttonClasses }">
            <div class="w-max">
              <button :class="buttonClasses.default" @click="perform(() => logout())">
                Log out
              </button>
            </div>
          </template>
        </ActionButton>
      </span>
    </template>
  </div>
</template>

<script setup>
import { ref, computed } from 'vue'
import {
  subscription_path,
  subscriptions_path,
  system_settings_path,
  login_path,
  logout_path,
} from '@/routes.js'
import { requestJSON } from '@/requests.js'
import ActionButton from '@/vue_components/ActionButton.vue'

const props = defineProps({
  currentUser: {
    type: Object,
    default: null,
  },
})

const currentUser = computed(() => props.currentUser)
const subscriptions = computed(() => currentUser.value.subscriptions)

const logout = async () => {
  const { response } = requestJSON(logout_path(), { method: 'delete' })

  if (!response.redirected) {
    alert('Unable to log out!')
  }
}
</script>
