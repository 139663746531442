<template>
  <div class="space-y-4">
    <h2 class="text-xl font-light uppercase text-center space-y-2">
      <SvgIcon icon="checkmark-outline" />
      <div>Confirm Payment</div>
    </h2>

    <div v-if="errors.base" class="bg-red-100 border border-red-400 px-4 py-2 text-center">
      {{ errors.base }}
    </div>

    <p class="text-gray-500 text-center px-4">
      You'll be charged {{ currency(initialPaymentCost) }} for your subscription and student annual
      passes. Please note, your subscription will automatically renew annually.
    </p>

    <PaymentMethodListItem v-if="paymentMethod" :payment-method="paymentMethod" />

    <div class="flex justify-center">
      <Button variant="primary" class="space-x-2" @click="submit" :disabled="submitting">
        <LoadingSpinner v-if="submitting" size="small" />
        <span class="inline-block">Process Payment</span>
      </Button>
    </div>
  </div>
</template>

<script setup>
import { ref, computed, inject } from 'vue'
import Button from '@/vue_components/Button.vue'
import SvgIcon from '@/vue_components/SvgIcon.vue'
import LoadingSpinner from '@/vue_components/LoadingSpinner.vue'
import PaymentMethodListItem from '../PaymentMethodListItem.vue'
import { currency } from '@/currency.js'
import { requestJSON } from '@/requests.js'
import { finish_setup_subscription_path } from '@/routes.js'
import useStore from '../../store.js'

const { state, loadSubscription } = useStore()
const submitting = ref(false)
const errors = ref({})

const paymentMethod = inject('paymentMethod')

const initialPaymentCost = computed(() => {
  return (
    state.subscription.cost +
    state.subscription.inactive_membership_passes.reduce(
      (sum, membershipPass) => sum + membershipPass.cost,
      0,
    )
  )
})

const submit = async () => {
  submitting.value = true
  errors.value = {}

  const { response, body } = await requestJSON(
    finish_setup_subscription_path(state.subscription.id),
    { method: 'PATCH' },
  )

  if (response.ok) {
    loadSubscription()
  } else {
    errors.value = response.errors ?? { base: "Something didn't work." }
  }

  submitting.value = false
}
</script>
