<template>
  <div v-if="subscription.needs_setup && can.setupSubscription">
    <FinishSetup />
  </div>

  <div v-else class="mt-8">
    <h1 class="block px-8 text-xl font-extrabold my-4 capitalize">
      {{ subscription.name }} Subscription
    </h1>

    <div v-if="subscription.locked" class="px-4">
      <Callout variant="danger">
        <div class="space-y-2">
          <h1 v-if="subscription.cancelled" class="font-bold text-xl">
            Your subscription has ended!
          </h1>
          <h1 v-else class="font-bold text-xl">Your subscription is locked!</h1>
          <p v-if="subscription.active && !subscription.marked_for_cancellation">
            None of your subscribers will receive content until your subscription is renewed. You
            will also be unable to add or remove subscribers.
          </p>
          <p v-else>
            You will no longer receive content updates and your subscription will be inactive.
          </p>
        </div>
      </Callout>
    </div>

    <div v-else-if="subscription.marked_for_cancellation" class="px-4">
      <Callout variant="danger">
        <div class="space-y-2">
          <h1 class="font-bold text-xl">Automatic renewal has been turned off</h1>
          <p>
            Your subscription will remain active until
            {{ fullDate(subscription.next_renewal_at) }}
            and will then be locked.
          </p>
        </div>
      </Callout>
    </div>

    <div v-else-if="subscription.failed_renewal" class="px-4">
      <Callout variant="alert">
        <div class="space-y-2">
          <h1 class="font-bold text-xl">Your subscription payment failed!</h1>
          <p>
            We will continue to try to renew your subscription automatically. If the automatic
            renewal fails too many times, your subscription will be locked and your subscribers will
            no longer receive content.
          </p>

          <p>
            We last tried to renew your subscription on
            {{ fullDate(subscription.last_renewal_attempted_at) }}. We've tried
            {{ pluralize('time', subscription.failed_renewal_attempts, { inclusive: true }) }} so
            far. We'll try another
            {{ pluralize('time', subscription.remaining_renewal_attempts, { inclusive: true }) }}
            before your subscription will be locked.
          </p>
        </div>
      </Callout>
    </div>

    <div
      v-if="
        subscription.active &&
        paymentMethods.length == 0 &&
        !subscription.marked_for_cancellation &&
        !subscription.locked &&
        paymentMethodsLoaded
      "
      class="px-4"
    >
      <Callout variant="alert">
        <div class="space-y-2">
          <h1 class="font-bold text-xl">Billing Information Needed</h1>
          <p>
            Please
            <router-link to="billing" class="underline">add a payment method</router-link>
            to ensure your subscription can be renewed when it expires.
          </p>
        </div>
      </Callout>
    </div>

    <div v-if="subscription.free_trial_active" class="px-4">
      <Callout variant="notice">
        <div class="space-y-2">
          <h1 class="font-bold text-xl">Free Trial Subscription</h1>
          <p>
            Your free trial is active until
            {{ fullDate(subscription.free_trial_ends_at) }}. Your subscription will automatically
            activate at the end of the free trial period, or you can
            <router-link to="subscription" class="underline text-blue-500">
              activate it immediately at any time
            </router-link>
            .
          </p>
        </div>
      </Callout>
    </div>

    <div class="flex flex-row">
      <nav class="block min-w-[250px] max-w-[400px] w-[20%] p-4 space-y-2">
        <ul class="block space-y-1">
          <li>
            <router-link to="members" :class="linkClasses" :active-class="activeLinkClasses">
              <SvgIcon icon="user-solid-circle" class="mr-2" />
              <span>Students</span>
              <span
                v-if="showFirstStudentAlert"
                class="ml-auto animate-rotate-back-and-forth"
                title="You need to invite your first student!"
              >
                <SvgIcon icon="brightness-down" class="text-red-500" />
              </span>
            </router-link>
          </li>
          <li>
            <router-link to="mentors" :class="linkClasses" :active-class="activeLinkClasses">
              <SvgIcon icon="user-group" class="mr-2" />
              <span>Mentors</span>
              <span
                v-if="showFirstMentorAlert"
                class="ml-auto animate-rotate-back-and-forth"
                title="You need to invite your first mentor!"
              >
                <SvgIcon icon="brightness-down" class="text-red-500" />
              </span>
            </router-link>
          </li>
          <li>
            <router-link to="billing" :class="linkClasses" :active-class="activeLinkClasses">
              <SvgIcon icon="credit-card" class="mr-2" />
              <span>Billing</span>
            </router-link>
          </li>
          <li>
            <router-link to="organization" :class="linkClasses" :active-class="activeLinkClasses">
              <SvgIcon icon="information-outline" class="mr-2" />
              <span>{{ organizationLabel }}</span>
            </router-link>
          </li>
          <li>
            <router-link to="subscription" :class="linkClasses" :active-class="activeLinkClasses">
              <SvgIcon icon="calendar" class="mr-2" />
              <span>Subscription</span>
            </router-link>
          </li>
        </ul>
      </nav>

      <div class="p-4 flex-1">
        <router-view></router-view>
      </div>
    </div>

    <Modal v-if="loading" content-size="content">
      <div class="flex justify-center p-8">
        <LoadingSpinner class="text-blue-500" />
      </div>
    </Modal>
  </div>
</template>

<script setup>
import { computed } from 'vue'
import Callout from '@/vue_components/Callout.vue'
import Modal from '@/vue_components/Modal.vue'
import LoadingSpinner from '@/vue_components/LoadingSpinner.vue'
import SvgIcon from '@/vue_components/SvgIcon.vue'
import FinishSetup from './FinishSetup/Index.vue'
import useStore from '../store.js'
import { pluralize } from '@/inflections.js'
import { fullDate } from '@/formatting.js'

const { state, can, showFirstStudentAlert, showFirstMentorAlert } = useStore()

const subscription = computed(() => state.subscription)
const paymentMethods = computed(() => state.paymentMethods)
const paymentMethodsLoaded = computed(() => state.paymentMethodsLoaded)
const loading = computed(() => state.loading)
const organizationLabel = computed(() =>
  subscription.value.category === 'family' ? 'My Church' : 'Organization',
)

const linkClasses = 'flex items-center px-4 py-2'
const activeLinkClasses = 'border-2 border-blue-500 rounded-md'
</script>
