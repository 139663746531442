<template>
  <div class="space-y-4">
    <h2 class="text-xl font-light uppercase text-center space-y-2">
      <SvgIcon icon="credit-card" />
      <div>Set Up Payment</div>
    </h2>

    <div v-if="errors.base" class="bg-red-200 border-2 border-red-500 p-4 rounded-md">
      {{ errors.base }}
    </div>

    <div class="space-y-4">
      <div>
        <label>Name on card</label>
        <TextInput v-model="stripeCardName" />
        <FieldError :error="errors.stripeCardName" />
      </div>

      <div class="flex space-x-4">
        <div class="flex-2">
          <label>Card Number</label>
          <div id="stripe-card-number"></div>
        </div>

        <div class="flex-1">
          <label>Exp Date</label>
          <div id="stripe-card-expiry"></div>
        </div>

        <div class="flex-1">
          <label>CVC</label>
          <div id="stripe-card-cvc"></div>
        </div>
      </div>

      <div class="grid grid-cols-2 gap-4">
        <div>
          <label>Billing Zip Code</label>
          <TextInput v-model="stripeCardZip" />
          <FieldError :error="errors.stripeCardZip" />
        </div>
      </div>
    </div>

    <div class="flex justify-center mt-8">
      <Button variant="primary" class="space-x-2" @click="submit" :disabled="submitting">
        <LoadingSpinner v-if="submitting" size="small" />
        <span>{{ addPaymentMethodCallToAction }}</span>
      </Button>
    </div>
  </div>
</template>

<script setup>
import { ref, computed, onMounted } from 'vue'
import Button from '@/vue_components/Button.vue'
import TextInput from '@/vue_components/TextInput.vue'
import FieldError from '@/vue_components/FieldError.vue'
import SvgIcon from '@/vue_components/SvgIcon.vue'
import LoadingSpinner from '@/vue_components/LoadingSpinner.vue'
import PaymentMethodListItem from '../PaymentMethodListItem.vue'
import useStore from '../../store.js'
import { withStripe } from '@/stripe.js'
import { currency } from '@/currency.js'

const emit = defineEmits(['next'])

const { state, buildPaymentMethod, createPaymentMethod } = useStore()

const stripeCardName = ref('')
const stripeCardZip = ref('')
const cardNumberElement = ref(null)
const cardExpiryElement = ref(null)
const cardCvcElement = ref(null)
const stripeSetupIntent = ref(null)

const submitting = ref(false)
const errors = ref({})

const addPaymentMethodCallToAction = computed(() =>
  state.subscription.free_trial_active
    ? 'Add Payment Method & Start Free Trial'
    : 'Add Payment Method & Review',
)

const validate = () => {
  errors.value = {}

  if (stripeCardName.value == '') {
    errors.value['stripeCardName'] ||= 'Name on card is required'
  }

  if (stripeCardZip.value == '') {
    errors.value['stripeCardZip'] ||= 'Billing zip code is required'
  }

  return Object.keys(errors.value).length === 0
}

const submit = () => {
  if (!validate()) {
    return
  }

  submitting.value = true

  withStripe(async (stripe) => {
    const result = await stripe.confirmCardSetup(stripeSetupIntent.value.client_secret, {
      payment_method: {
        card: cardNumberElement.value,
        billing_details: {
          name: stripeCardName.value,
          address: {
            postal_code: stripeCardZip.value,
          },
        },
      },
    })

    if (result.error) {
      errors.value = { base: result.error }
      console.errror(result)
    } else {
      const { response, body } = await createPaymentMethod(result.setupIntent.payment_method)

      if (response.ok) {
        state.paymentMethods = [...state.paymentMethods, body]
        emit('next')
      } else {
        if (body.errors) {
          errors.value = body.errors
        }
        console.error(response)
      }
    }

    submitting.value = false
  })
}

onMounted(async () => {
  stripeSetupIntent.value = await buildPaymentMethod()

  withStripe((stripe) => {
    const elements = stripe.elements()

    cardNumberElement.value = elements.create('cardNumber')
    cardExpiryElement.value = elements.create('cardExpiry')
    cardCvcElement.value = elements.create('cardCvc')

    cardNumberElement.value.mount('#stripe-card-number')
    cardExpiryElement.value.mount('#stripe-card-expiry')
    cardCvcElement.value.mount('#stripe-card-cvc')
  })
})
</script>
