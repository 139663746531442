<template>
  <input
    ref="input"
    :type="type"
    :name="name"
    class="
      block
      w-full
      p-2
      text-black
      border border-gray-200 border-solid
      rounded-sm
      shadow-sm
      focus:ring-0 focus:border-gray-300
    "
    :value="modelValue"
    @input="handleInput"
  />
</template>

<script setup>
import { ref, computed, onMounted } from 'vue'

const props = defineProps({
  type: String,
  name: String,
  modelValue: String,
  autofocus: {
    type: Boolean,
    default: false,
  },
  mask: {
    type: Function,
    default: (value) => value,
  },
})

const emit = defineEmits(['update:modelValue'])
const input = ref(null)
const previous = ref(props.modelValue)

const handleInput = (e) => {
  const value = e.target.value
  const previousValue = previous.value
  const maskedValue = props.mask(value)
  const maskedPreviousValue = props.mask(previousValue)

  const isChanged = maskedValue !== maskedPreviousValue

  if (isChanged) {
    e.target.value = maskedValue
    emit('update:modelValue', maskedValue)
    previous.value = value
  } else {
    e.preventDefault()
    e.target.value = previousValue
  }
}

onMounted(() => {
  if (props.autofocus && input.value) {
    input.value.focus()
  }
})
</script>
