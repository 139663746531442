import { computed } from 'vue'
import { useState } from '@/states.js'

const { state, setState } = useState({
  formData: {
    organization: {
      name: '',
      denomination: '',
      size: '',
      phone_number: '',
      address_street: '',
      address_city: '',
      address_state: '',
      address_postal_code: '',
    },
    owner: {
      first_name: '',
      last_name: '',
      email: '',
      cell_phone: '',
      job_title: '',
    },
    subscription: {
      subscription_type_id: null,
      number_of_memberships: 0,
    },
    account: {
      password: '',
      password_confirmation: '',
    },
  },
  subscription_types: [],
})

const useStore = () => {
  const selectedSubscriptionType = computed(() =>
    state.subscription_types.find(
      (subscriptionType) =>
        subscriptionType.id === state.formData.subscription.subscription_type_id,
    ),
  )

  return {
    state,
    setState,
    selectedSubscriptionType,
  }
}

export default useStore
