<template>
  <Modal>
    <Form method="post" :action="subscription_mentors_path(subscription.id)" @submit="handleSubmit">
      <div class="space-y-4 divide-y">
        <h1 class="text-xl font-light">Add Mentor</h1>

        <div class="space-y-4 pt-4">
          <MentorForm :mentor="mentor" :errors="errors" />

          <div class="grid grid-cols-2 gap-4">
            <Button variant="secondary" type="button" @click="emit('close')">Cancel</Button>
            <Button variant="primary" type="submit" :disabled="submitting">Add</Button>
          </div>
        </div>
      </div>
    </Form>
  </Modal>
</template>

<script setup>
import { ref, computed } from 'vue'
import Modal from '@/vue_components/Modal.vue'
import Form from '@/vue_components/Form.vue'
import Button from '@/vue_components/Button.vue'
import MentorForm from './MentorForm.vue'
import useStore from '../store.js'
import { alert } from '@/alerts.js'
import { submit } from '@/forms.js'
import { subscription_mentors_path } from '@/routes.js'

const emit = defineEmits(['close'])

const { state } = useStore()
const subscription = computed(() => state.subscription)
const mentor = ref({})

const submitting = ref(false)
const errors = ref({})

const handleSubmit = async ({ form }) => {
  submitting.value = true

  const response = await submit({ form })

  if (response.errors) {
    errors.value = response.errors
  } else {
    errors.value = {}
    state.subscription.mentors = [...state.subscription.mentors, response]
    alert('Mentor added!')
    emit('close')
  }

  submitting.value = false
}
</script>
