<template>
  <div class="space-y-4 lg:space-y-8">
    <h2 class="text-xl font-light uppercase text-center space-y-2">
      <SvgIcon icon="user-solid-circle" />
      <div>Let's get your account set up</div>
    </h2>

    <div>
      <label>Email</label>
      <TextInput v-model="state.formData.owner.email" type="text" disabled />
    </div>

    <div>
      <label>Password</label>
      <NewPasswordInput v-model="state.formData.account.password" />
      <FieldError :error="errors.password" />
    </div>

    <div>
      <label>Confirm Password</label>
      <TextInput v-model="state.formData.account.password_confirmation" type="password" />
      <FieldError :error="errors.password_confirmation" />
    </div>

    <div class="grid grid-cols-2 gap-4 pt-4">
      <Button variant="secondary" @click="back">
        <SvgIcon icon="cheveron-left" />
        <span class="inline-block ml-1">Back</span>
      </Button>

      <Button variant="primary" @click="next" :disabled="!canProceed">
        <span class="inline-block mr-1">Next</span>
        <SvgIcon icon="cheveron-right" />
      </Button>
    </div>
  </div>
</template>

<script setup>
import { ref, computed } from 'vue'
import TextInput from '@/vue_components/TextInput.vue'
import NewPasswordInput from '@/vue_components/NewPasswordInput.vue'
import FieldError from '@/vue_components/FieldError.vue'
import PhoneNumberInput from '@/vue_components/PhoneNumberInput.vue'
import SvgIcon from '@/vue_components/SvgIcon.vue'
import Button from '@/vue_components/Button.vue'
import { requestJSON } from '@/requests.js'
import useStore from '../store.js'

const emit = defineEmits(['next', 'back'])

const { state } = useStore()
const errors = ref({})

const passwordsAreSet = computed(
  () => state.formData.account.password && state.formData.account.password_confirmation,
)
const passwordsMatch = computed(
  () => state.formData.account.password == state.formData.account.password_confirmation,
)
const canProceed = computed(() => passwordsAreSet.value && passwordsMatch.value)

const back = () => {
  emit('back')
}

const next = async () => {
  errors.value = {}

  const { body } = await requestJSON('/join', { method: 'post', body: state.formData })

  if (body.errors && body.errors.account && Object.keys(body.errors.account).length > 0) {
    errors.value = body.errors.account
  } else {
    emit('next')
  }
}
</script>
