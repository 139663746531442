import { createApp, h } from 'vue'
import SiteAlerts from './components/SiteAlerts.vue'
import useStore from './store.js'

export default ({ el, props = {}, state = {} } = {}) => {
  const app = createApp({
    render() {
      return h(SiteAlerts, props)
    },
  })

  const { addAlert } = useStore()

  const alerts = props.alerts || []

  for (let i = 0; i < alerts.length; i++) {
    addAlert({ message: alerts[i], duration: 3000 })
  }

  return app.mount(el)
}
