<template>
  <div v-if="subscriptionType" class="space-y-4 lg:space-y-8">
    <h2 class="text-xl font-light uppercase text-center space-y-2">
      <span class="inline-flex items-center space-x-1">
        <SvgIcon icon="thumbs-up" class="-translate-y-0.5" />
        <SvgIcon icon="thumbs-down" class="translate-y-0.5" />
      </span>
      <div>Make sure everything looks good</div>
    </h2>

    <div class="grid grid-cols-2 grid-wrap gap-4 lg:gap-8">
      <Box class="space-y-2">
        <h3 class="font-bold">Your Information</h3>

        <div class="grid grid-cols-2 grid-wrap gap-4 lg:gap-8">
          <div>
            <p class="text-sm text-gray-500">Name</p>
            <p>{{ state.formData.owner.first_name }} {{ state.formData.owner.last_name }}</p>
          </div>

          <div>
            <p class="text-sm text-gray-500">Cell Phone</p>
            <p>{{ phoneNumber(state.formData.owner.cell_phone) }}</p>
          </div>

          <div>
            <p class="text-sm text-gray-500">Job Title</p>
            <p>{{ state.formData.owner.job_title }}</p>
          </div>

          <div class="col-span-2">
            <p class="text-sm text-gray-500">Email</p>
            <p class="text-sm text-gray-400">
              This email address will be used for communication as well as your account login.
            </p>
            <p>{{ state.formData.owner.email }}</p>
          </div>
        </div>
      </Box>

      <Box class="space-y-2">
        <h3 class="font-bold">Organization Information</h3>

        <div class="grid grid-cols-2 grid-wrap gap-4 lg:gap-8">
          <div>
            <p class="text-sm text-gray-500">Name</p>
            <p>{{ state.formData.organization.name }}</p>
          </div>

          <div>
            <p class="text-sm text-gray-500">Denomination</p>
            <p>{{ state.formData.organization.denomination }}</p>
          </div>

          <div>
            <p class="text-sm text-gray-500">Church Size</p>
            <p>{{ state.formData.organization.size }}</p>
          </div>

          <div>
            <p class="text-sm text-gray-500">Phone Number</p>
            <p>{{ phoneNumber(state.formData.organization.phone_number) }}</p>
          </div>

          <div class="col-span-2">
            <p class="text-sm text-gray-500">Address</p>
            <p>
              {{ state.formData.organization.address_street }},
              {{ state.formData.organization.address_city }},
              {{ state.formData.organization.address_state }}
              {{ state.formData.organization.address_postal_code }}
            </p>
          </div>
        </div>
      </Box>

      <div class="p-2 col-span-2 space-y-2">
        <div>
          <p class="text-gray-500">Subscription</p>
          <p class="font-bold text-xl">{{ subscriptionType.name }}</p>
        </div>

        <table class="w-full">
          <tbody>
            <tr>
              <td class="border border-gray-200 p-2">Annual Subscription</td>
              <td class="border border-gray-200 p-2 text-right">1</td>
              <td class="border border-gray-200 p-2 text-right">
                {{ currency(subscriptionType.price) }}
              </td>
            </tr>

            <tr>
              <td class="border border-gray-200 p-2">Student Annual Passes</td>
              <td class="border border-gray-200 p-2 text-right">
                {{ state.formData.subscription.number_of_memberships }}
              </td>
              <td class="border border-gray-200 p-2 text-right">
                {{ currency(membershipPassCost) }}
              </td>
            </tr>

            <tr>
              <td colspan="2"></td>
              <td class="border border-gray-200 p-2 text-right">
                <div v-if="subscriptionType.free_trial_duration">
                  <div class="line-through">
                    {{ currency(subscriptionCost) }}
                  </div>
                  <div class="px-4 py-2 bg-pink-500 text-center">
                    Free for {{ subscriptionType.free_trial_duration }} months
                  </div>
                </div>
                <div v-else>
                  {{ currency(subscriptionCost) }}
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>

    <div class="grid grid-cols-2 gap-4 pt-4">
      <Button variant="secondary" @click="back" :disabled="submitting">
        <SvgIcon icon="cheveron-left" />
        <span class="inline-block ml-1">Back</span>
      </Button>

      <Button variant="primary" @click="next" :disabled="submitting">
        <span class="inline-block mr-1">Complete</span>
        <SvgIcon icon="cheveron-right" />
      </Button>
    </div>
  </div>
</template>

<script setup>
import { ref, computed } from 'vue'
import Box from '@/vue_components/Box.vue'
import SvgIcon from '@/vue_components/SvgIcon.vue'
import Button from '@/vue_components/Button.vue'
import { phoneNumber } from '@/formatting.js'
import { currency } from '@/currency.js'
import { requestJSON } from '@/requests.js'
import useStore from '../store.js'

const emit = defineEmits(['next', 'back'])

const { state, selectedSubscriptionType } = useStore()
const subscription = computed(() => state.formData.subscription)
const subscriptionTypes = computed(() => state.subscription_types)
const subscriptionType = computed(() => selectedSubscriptionType.value)
const membershipPassType = computed(() => subscriptionType.value.default_membership_pass_type)
const membershipPassCost = computed(
  () => state.formData.subscription.number_of_memberships * membershipPassType.value.price,
)
const subscriptionCost = computed(() => subscriptionType.value.price + membershipPassCost.value)

const submitting = ref(false)

const back = () => {
  emit('back')
}

const next = async () => {
  submitting.value = true

  const { body } = await requestJSON('/join', {
    method: 'post',
    body: { ...state.formData, commit: true },
  })

  if (body.errors) {
    console.error(body.errors)
  } else {
    window.location.href = body.redirect_url
  }

  submitting.value = false
}
</script>
