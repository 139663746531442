import { createApp, h } from 'vue'
import JoinForm from './components/JoinForm.vue'
import useStore from './store.js'

export default ({ el, props = {}, state = {} } = {}) => {
  const { setState } = useStore()

  console.log(state)

  setState(state)

  const app = createApp({
    render() {
      return h(JoinForm, props)
    },
  })

  return app.mount(el)
}
