<template>
  <BrandedLayout title="Update Password" :background="loginBackground">
    <div v-if="valid">
      <Shaker v-slot="{ shake }">
        <Form
          method="post"
          :action="password_updates_path()"
          @submit="(e) => handleSubmit({ ...e, onError: shake })"
        >
          <input type="hidden" name="password_update[email]" :value="email" />
          <input type="hidden" name="password_update[reset_token]" :value="reset_token" />

          <div class="space-y-4 lg:space-y-8">
            <div v-if="errors.base" class="bg-red-200 border-2 border-red-500 p-4 rounded-md">
              {{ errors.base }}
            </div>

            <div>
              <label class="text-white">New Password</label>
              <NewPasswordInput
                type="password"
                name="password_update[password]"
                v-model="password"
              />
              <FieldError :error="errors.password_update?.password" />
            </div>

            <div>
              <label class="text-white">Confirm Password</label>
              <TextInput type="password" name="password_update[password_confirmation]" />
              <FieldError :error="errors.password_update?.password_confirmation" />
            </div>

            <div class="flex items-center space-x-4">
              <Button type="submit" variant="branded" :disabled="submitting">
                Update password
              </Button>
            </div>
          </div>
        </Form>
      </Shaker>
    </div>
    <div v-else>
      <div class="space-y-4 text-white lg:space-y-8">
        <h1 class="text-xl">🚫 Password Reset Expired</h1>

        <p>
          The link you are using to try to reset your password is no longer valid. No worries! Just
          <a :href="new_password_reset_path()">request a new link</a>
          .
        </p>

        <Button variant="branded" :href="new_password_reset_path()">Request a new link</Button>
      </div>
    </div>
  </BrandedLayout>
</template>

<script setup>
import { ref } from 'vue'
import BrandedLayout from '@/vue_components/BrandedLayout.vue'
import Button from '@/vue_components/Button.vue'
import Shaker from '@/vue_components/Shaker.vue'
import Box from '@/vue_components/Box.vue'
import Form from '@/vue_components/Form.vue'
import NewPasswordInput from '@/vue_components/NewPasswordInput.vue'
import TextInput from '@/vue_components/TextInput.vue'
import FieldError from '@/vue_components/FieldError.vue'
import { login_path, password_updates_path, new_password_reset_path } from '@/routes.js'
import { submit } from '@/forms.js'

import loginBackground from '@/images/login-background.jpg'

const props = defineProps({
  email: String,
  reset_token: String,
  valid: {
    type: Boolean,
    default: false,
  },
})

const errors = ref({})
const submitting = ref(false)
const password = ref('')

const handleSubmit = async ({ form, onError }) => {
  submitting.value = true

  const response = await submit({ form })

  if (response.errors) {
    if (onError) onError()
    errors.value = response.errors
  }

  submitting.value = false
}
</script>
