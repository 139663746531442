<template>
  <ul class="flex items-center space-x-2">
    <template v-for="(step, index) in steps" :key="step">
      <li :class="stepClasses(index)">{{ step }}</li>
      <li v-if="index < steps.length - 1"><SvgIcon icon="cheveron-right" /></li>
    </template>
  </ul>
</template>

<script>
import { computed } from 'vue'
import SvgIcon from '@/vue_components/SvgIcon.vue'

export default {
  components: {
    SvgIcon,
  },
  props: {
    steps: {
      type: Array,
      default: [],
    },
    currentStep: {
      type: Number,
      default: 0,
    },
  },
  setup(props) {
    const completedClasses = ['text-blue-500']
    const currentClasses = ['text-blue-500 font-extrabold']
    const incompleteClasses = ['text-gray-500']

    const stepClasses = (stepIndex) => {
      if (stepIndex < props.currentStep) {
        return completedClasses
      } else if (stepIndex > props.currentStep) {
        return incompleteClasses
      } else {
        return currentClasses
      }
    }

    return {
      stepClasses,
    }
  },
}
</script>
