export const denominations = [
  '',
  'Adventist',
  'Anglican',
  'Baptist',
  'Bible',
  'Brethren',
  'Catholic',
  'Church of God',
  'Episcopal',
  'Evengelical Free',
  'Lutheran',
  'Methodist',
  'Pentecostal',
  'Reformed',
  'Southern Baptist',
  'Other',
]

export const sizeOptions = ['', '< 100', '100-200', '200-500', '500-1000', '1000+']
