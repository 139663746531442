<template>
  <div class="space-y-4">
    <div class="space-y-1">
      <Box
        v-for="membershipPassType in membershipPassTypes"
        :key="membershipPassType.id"
        class="cursor-pointer"
        :class="
          selectedMembershipPassType && selectedMembershipPassType.id === membershipPassType.id
            ? `bg-blue-500 text-white`
            : ``
        "
        @click="selectedMembershipPassType = membershipPassType"
      >
        <p class="font-bold text-xl">{{ membershipPassType.name }}</p>
        <p>{{ currency(membershipPassType.price) }} / pass</p>
      </Box>
    </div>

    <div class="grid grid-cols-2 gap-4">
      <div></div>
      <Button variant="primary" @click="selectPlan" :disabled="!selectedMembershipPassType">
        Select Plan
      </Button>
    </div>
  </div>
</template>

<script setup>
import { ref, computed, inject } from 'vue'
import Box from '@/vue_components/Box.vue'
import Button from '@/vue_components/Button.vue'
import useStore from '../../store.js'
import { currency } from '@/currency.js'

const action = inject('action')
const formData = inject('formData')
const cancel = inject('cancel')

const { state } = useStore()
const selectedMembershipPassType = ref(null)
const membershipPassTypes = computed(() => state.membershipPassTypes)

const selectPlan = () => {
  action('next', { membershipPassType: selectedMembershipPassType.value })
}
</script>
