<template>
  <Modal content-size="full">
    <div class="relative">
      <button class="absolute top-0 right-0" @click="emit('close')">
        <SvgIcon icon="close-outline" />
      </button>

      <div class="w-full space-y-6">
        <div class="space-y-1">
          <h1 class="text-xl font-medium">Invite new student</h1>
          <ProgressBreadcrumbs :steps="steps" :current-step="currentStep" />
        </div>

        <div v-if="errors.base" class="bg-red-200 border-2 border-red-500 p-4 rounded-md">
          {{ errors.base }}
        </div>

        <Form method="post" :action="membership_invitations_path()" @submit="handleSubmit">
          <input type="hidden" name="membership[subscription_id]" :value="subscription.id" />
          <Student v-show="currentState === 'student'" />
          <Parents v-show="currentState === 'parents'" />
          <Mentor v-show="currentState === 'mentor'" />
          <Review v-show="currentState === 'review'" />
        </Form>
      </div>
    </div>
  </Modal>
</template>

<script setup>
import { ref, computed, provide } from 'vue'
import Modal from '@/vue_components/Modal.vue'
import ProgressBreadcrumbs from '@/vue_components/ProgressBreadcrumbs.vue'
import Form from '@/vue_components/Form.vue'
import SvgIcon from '@/vue_components/SvgIcon.vue'
import Student from './Student.vue'
import Parents from './Parents.vue'
import Mentor from './Mentor.vue'
import Review from './Review.vue'
import { useStateMachine } from '@/state_machine.js'
import { membership_invitations_path } from '@/routes.js'
import useStore from '../../store.js'
import { requestJSON } from '@/requests.js'

const emit = defineEmits(['cancel', 'close'])

const { state, loadSubscription } = useStore()
const subscription = computed(() => state.subscription)

const errors = ref({})
const submitting = ref(false)
const formData = ref({
  subscription_id: subscription.value.id,
  first_name: '',
  last_name: '',
  email: '',
  cell_phone: '',
  school: '',
  grade_level: '',
  parents: [{ key: performance.now(), subscription_id: state.subscription.id }],
  mentor_relationship_attributes: null,
})

const { currentState, action } = useStateMachine({
  states: {
    student: {
      actions: {
        next: ({ goto }) => {
          goto('parents')
        },
      },
    },
    parents: {
      actions: {
        next: ({ goto }) => {
          goto('mentor')
        },
        back: ({ goto }) => {
          goto('student')
        },
      },
    },
    mentor: {
      actions: {
        next: ({ goto }) => {
          goto('review')
        },
        back: ({ goto }) => {
          goto('parents')
        },
      },
    },
    review: {
      actions: {
        back: ({ goto }) => {
          goto('mentor')
        },
      },
    },
  },
  initialState: 'student',
})

const steps = ['Student', 'Parents', 'Mentor', 'Review']

const currentStep = computed(() => {
  const currentStateToStep = {
    student: 0,
    parents: 1,
    mentor: 2,
    review: 3,
  }

  return currentStateToStep[currentState.value]
})

const handleSubmit = async ({ form, onError }) => {
  submitting.value = true

  const { body } = await requestJSON(form.action, {
    method: 'post',
    body: { membership: formData.value },
  })

  submitting.value = false

  if (body.errors) {
    if (onError) onError()
    errors.value = body.errors
  } else {
    await loadSubscription()
    emit('close')
  }
}

provide('formData', formData)
provide('currentState', currentState)
provide('action', action)
provide('errors', errors)
provide('submitting', submitting)
</script>
